import { RawMaterialNeeded } from './../Object_Classes/Machine/Machine';
import { AngularFireDatabase } from '@angular/fire/database';
import { Machine, ScheduleTracker, OEE, MonthOEE } from '../Object_Classes/Machine/Machine';
import { DataSnapshot } from '@angular/fire/database/interfaces';
export class MachineDB_controller {
  months: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "010", "011", "012"]
  constructor(private db: AngularFireDatabase) {
  }
  /*********************** Machine *********************************/
  getMachineWholeList(snapshot): Machine[] {
    let Machine_list: Machine[] = [];
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot1 => {
        if(!childSnapshot1.key.includes('@@')){
          let machine = new Machine();
          machine.Machine_No = childSnapshot1.key;
          machine.Machine_Code = childSnapshot1.child("Code").val();
          machine.MachineStatus = childSnapshot1.child("Machine Status").val();
          machine.MachineTonnage = childSnapshot1.child("Tonnage").val();
          let schedule_TrackList: ScheduleTracker[] = [];

          childSnapshot1.child("Schedule").forEach(childSnapshot2 => {
            let schedule_track = new ScheduleTracker;

            schedule_track.Machine_Schedule_No = childSnapshot2.key;
            schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
            schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
            schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
            schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
            schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
            schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
            schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
            schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
            schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
            schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
            schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
            schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();

            if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
              schedule_track.Machine_Schedule_Status = "Done";
            }

            schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
            schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
            schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
            schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
            schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
            schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
            schedule_track.Availability = childSnapshot2.child("_Availability").val();
            schedule_track.Performance = childSnapshot2.child("_Performance").val();
            schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();
            schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;

            var currentDate = new Date();
            if (new Date(schedule_track.Machine_Schedule_Start_Date).getTime() <= currentDate.getTime() &&
              new Date(schedule_track.Machine_Schedule_End_Date).getTime() >= currentDate.getTime()) {
              schedule_TrackList.push(schedule_track);
            }
            schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;
            this.getPart(schedule_track);

          });
          machine.Schedule_Track = schedule_TrackList;
          Machine_list.push(machine);
        }

      });
      return Machine_list;
    }
  }

  getSpecifyMachine(snapshot): Machine {
    let machine = new Machine();
    machine.Schedule_Track = [];
    if (snapshot.exists()) {
      machine.Machine_No = snapshot.key;
      machine.Machine_Code = snapshot.child("Code").val();
      machine.MachineStatus = snapshot.child("Machine Status").val();
      machine.MachineTonnage = snapshot.child("Tonnage").val();

      let schedule_TrackList: ScheduleTracker[] = [];
      snapshot.child("Schedule").forEach(childSnapshot2 => {
        let schedule_track = new ScheduleTracker;

        if(childSnapshot2.child("_Machine_Schedule_Status").val() === 'Done'){
          return;
        }

        schedule_track.Machine_Schedule_No = childSnapshot2.key;
        schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
        schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
        schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
        schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
        schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
        schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
        schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
        schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
        schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
        schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
        schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
        schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();
        schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val();
        schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val();
        schedule_track.DateDown_Start = childSnapshot2.child("_DateDown_Start").val();
        schedule_track.DateDown_End = childSnapshot2.child("_DateDown_End").val();
        schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime:").val();
        schedule_track.Availability = childSnapshot2.child("_Availability").val();
        schedule_track.Performance = childSnapshot2.child("_Performance:").val();
        schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();

        if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
          schedule_track.Machine_Schedule_Status = "Done";
        }

        schedule_track.HourLeft = childSnapshot2.child("_HourLeft").val();
        schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
        schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;
        this.getPart(schedule_track);
        schedule_TrackList.push(schedule_track);
      });
      machine.Schedule_Track = schedule_TrackList;
      return machine
    }
  }


  async getMachineList(snapshot): Promise<Machine[]> {
    let machinelist: Machine[] = [];

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(!childSnapshot.key.includes('@@')){
        const m = new Machine();
        m.Machine_No = childSnapshot.key;
        m.Machine_Code = childSnapshot.child("Code").val();
        m.MachineStatus = childSnapshot.child("Machine Status").val();
        m.MachineTonnage = childSnapshot.child("Tonnage").val();
        m.Brand = childSnapshot.child("Brand").val();

        let schedule_TrackList: ScheduleTracker[] = [];
        let previousSchedule: ScheduleTracker[] = [];


        childSnapshot.child("Schedule").forEach( childSnapshot2 => {
          let schedule_track = new ScheduleTracker;

          schedule_track.Machine_Schedule_No = childSnapshot2.key;
          schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
          schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
          schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
          schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
          schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
          schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
          schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
          schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
          schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
          schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();

          if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }

          schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
          schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
          schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
          schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
          schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
          schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
          schedule_track.Availability = childSnapshot2.child("_Availability").val();
          schedule_track.Performance = childSnapshot2.child("_Performance").val();
          schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();
          schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;

          if (schedule_track.Machine_Schedule_Status !== 'Done' ) {
            schedule_TrackList.push(schedule_track);
          }
          else{
            previousSchedule.push(schedule_track);
          }
        });

        childSnapshot.child("Archive").forEach( childSnapshot2 => {
          let schedule_track = new ScheduleTracker;
          schedule_track.Machine_Schedule_No = childSnapshot2.key;

          schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
          schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
          schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
          schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
          schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
          schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
          schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
          schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
          schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
          schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();

          if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }

          schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
          schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
          schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
          schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
          schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
          schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
          schedule_track.Availability = childSnapshot2.child("_Availability").val();
          schedule_track.Performance = childSnapshot2.child("_Performance").val();
          schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();
          schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;
          previousSchedule.push(schedule_track);
        });

        m.Schedule_Track = schedule_TrackList;
        m.PreviousScheduleTrack = previousSchedule;
        let oee_trackList: OEE[] = [];

        childSnapshot.child("OEE").forEach(childSnapshot3 => {
          let oee = new OEE;
          oee.Year = childSnapshot3.key;
          let oee_monthTrackList: MonthOEE[] = [];
          for (let i = 0; i < this.months.length; i++) {
            if (childSnapshot3.child(this.months[i]).val() != null) {
              let monthOEE = new MonthOEE;
              childSnapshot3.child(this.months[i]).forEach(childSnapshot4 => {

                monthOEE.Month = this.months[i];
                if (childSnapshot4.key.match("Monthly OEE")) {
                  monthOEE.This_Month_OEE = childSnapshot4.val();
                }
                if (childSnapshot4.key.match("No Job Hours")) {
                  monthOEE.Total_No_Jobs_Hours = childSnapshot4.val();
                }
                if (childSnapshot4.key.match("Total Job")) {
                  monthOEE.Total_Jobs = childSnapshot4.val();
                }
              });
              oee_monthTrackList.push(monthOEE);
            }
          }
          oee.Monthly_OEE = oee_monthTrackList;
          oee_trackList.push(oee);
        });
        m.OEE = oee_trackList;
        machinelist.push(m);
      }
      });

    }
    return machinelist;
  }

  async getMachineListOEE(snapshot):Promise<Machine[]>{
    let machinelist: Machine[] = [];

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(!childSnapshot.key.includes('@@')){
        const m = new Machine();
        m.Machine_No = childSnapshot.key;
        m.Machine_Code = childSnapshot.child("Code").val();
        m.MachineStatus = childSnapshot.child("Machine Status").val();
        m.MachineTonnage = childSnapshot.child("Tonnage").val();
        m.Brand = childSnapshot.child("Brand").val();

        let schedule_TrackList: ScheduleTracker[] = [];
        let previousSchedule: ScheduleTracker[] = [];

        childSnapshot.child("Schedule").forEach( childSnapshot2 => {
          let schedule_track = new ScheduleTracker;
          schedule_track.Machine_Schedule_No = childSnapshot2.key;
          schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
          schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
          schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
          schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
          schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
          schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
          schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
          schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
          schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
          schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();

          if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }

          schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
          schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
          schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
          schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
          schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
          schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
          schedule_track.Availability = childSnapshot2.child("Availability").val();
          schedule_track.Performance = childSnapshot2.child("Performance").val();
          schedule_track.Effectiveness = childSnapshot2.child("Effectiveness").val();

          schedule_track.OEEDone = schedule_track.Availability > 0 && schedule_track.Performance > 0 && schedule_track.Effectiveness > 0;
          schedule_track.OEE = schedule_track.Availability * schedule_track.Performance * schedule_track.Effectiveness;
          schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;

          // this.getPart(schedule_track);

          if(schedule_track.OEEDone){
           this.db.database.ref('QC')
            .child(schedule_track.Machine_Schedule_PO_No).child(schedule_track.Machine_Schedule_Part_No).once('value').then(snap=>{
              if(snap.exists()){
                schedule_track.QCQuantity = snap.child('Accepted Qty').val();
              }
            })
          }

          if (schedule_track.Machine_Schedule_Status !== 'Done' ) {
            schedule_TrackList.push(schedule_track);
          }else{
            previousSchedule.push(schedule_track);
          }

        });

        childSnapshot.child("Archive").forEach( childSnapshot2 => {
          let schedule_track = new ScheduleTracker;

          schedule_track.Machine_Schedule_No = childSnapshot2.key;
          schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
          schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
          schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
          schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
          schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
          schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
          schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
          schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
          schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
          schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();

          if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }

          schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
          schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
          schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
          schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
          schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
          schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
          schedule_track.Availability = childSnapshot2.child("_Availability").val();
          schedule_track.Performance = childSnapshot2.child("_Performance").val();
          schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();
          schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;
          schedule_track.Availability = schedule_track.Availability>1?1:schedule_track.Availability;
          schedule_track.Effectiveness = schedule_track.Effectiveness>1?1:schedule_track.Effectiveness;
          schedule_track.Performance = schedule_track.Performance>1?1:schedule_track.Performance;

          schedule_track.OEEDone = schedule_track.Availability > 0 && schedule_track.Performance > 0 && schedule_track.Effectiveness > 0;
          schedule_track.OEE = schedule_track.Availability * schedule_track.Performance * schedule_track.Effectiveness;
          this.getPart(schedule_track);

          if(schedule_track.OEEDone){
            this.db.database.ref('QC')
             .child(schedule_track.Machine_Schedule_PO_No).child(schedule_track.Machine_Schedule_Part_No).once('value').then(snap=>{
               if(snap.exists()){
                 schedule_track.QCQuantity = snap.child('Accepted Qty').val();
               }
             })
           }
          previousSchedule.push(schedule_track);
        });

        m.Schedule_Track = schedule_TrackList;
        m.PreviousScheduleTrack = previousSchedule;
        machinelist.push(m);
      }
      });

    }
    return machinelist;
  }

  async getMachineListForRawMaterial(snapshot): Promise<Machine[]>{
    let machinelist: Machine[] = [];

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(!childSnapshot.key.includes('@@')){
        const m = new Machine();
        m.Machine_No = childSnapshot.key;
        m.Machine_Code = childSnapshot.child("Code").val();
        m.MachineStatus = childSnapshot.child("Machine Status").val();
        m.MachineTonnage = childSnapshot.child("Tonnage").val();
        m.Brand = childSnapshot.child("Brand").val();

        let schedule_TrackList: ScheduleTracker[] = [];

        childSnapshot.child("Schedule").forEach( childSnapshot2 => {
          let schedule_track = new ScheduleTracker;

          schedule_track.Machine_Schedule_No = childSnapshot2.key;
          schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
          schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
          schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
          schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
          schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
          schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
          schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
          schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
          schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
          schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();

          if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }

          schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
          schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
          schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
          schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
          schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
          schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
          schedule_track.Availability = childSnapshot2.child("_Availability").val();
          schedule_track.Performance = childSnapshot2.child("_Performance").val();
          schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();

          schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;
          if (schedule_track.Machine_Schedule_Status === 'Done' ) {
            schedule_TrackList.push(schedule_track);
          }
        });

        m.Schedule_Track = schedule_TrackList;
        machinelist.push(m);
      }
      });

    }
    return machinelist;
  }
  async getMachineListForQC(snapshot): Promise<Machine[]> {
    let machinelist: Machine[] = [];

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
          const m = new Machine();

          m.Machine_No = childSnapshot.key;
          m.Brand = childSnapshot.child("Brand").val();
          m.Machine_Code = childSnapshot.child("Code").val();
          m.MachineStatus = childSnapshot.child("Machine Status").val();
          m.MachineTonnage = childSnapshot.child("Tonnage").val();

          let schedule_TrackList: ScheduleTracker[] = [];
          childSnapshot.child("Schedule").forEach( childSnapshot2 => {
            let schedule_track = new ScheduleTracker;

            schedule_track.Machine_Number = m.Machine_No;
            schedule_track.Machine_Schedule_No = childSnapshot2.key;
            schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
            schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
            schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
            schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
            schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
            schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
            schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
            schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
            schedule_track.Machine_Schedule_Status =childSnapshot2.child("_Machine_Schedule_Status").val();
            schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
            schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
            schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();
            schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val();
            schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val();
            schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
            schedule_track.Effectiveness = childSnapshot2.child('Effectiveness').val();

            this.db.database.ref('QC').child(schedule_track.Machine_Schedule_PO_No).child(schedule_track.Machine_Schedule_Part_No).on('value', snap=>{
              schedule_track.QCRejected = 0;

              if(snap.exists()){
                schedule_track.QCDone = true;
                schedule_track.QCAccepted = snap.child('Accepted Qty').val();

                if(schedule_track.Acc_Qty > 0){
                  let eff = schedule_track.QCAccepted/schedule_track.Acc_Qty

                  if(schedule_track.Effectiveness !== eff){
                    schedule_track.Effectiveness = eff

                    this.db.database.ref('Machine').child(schedule_track.Machine_Number).child('Schedule').child(schedule_track.Machine_Schedule_No).update({Effectiveness: schedule_track.Effectiveness})
                  }
                }

                snap.child('Rejected Part').forEach((childSnapshot3)=>{
                  schedule_track.QCRejected += parseInt(childSnapshot3.val());
                })

                schedule_track.QCDate = new Date(snap.child('QC Date').val());
              }else{
                schedule_track.QCDone = false;
              }
            })

            this.getPart(schedule_track);
            schedule_TrackList.push(schedule_track);
          });

          m.Schedule_Track = schedule_TrackList;
          machinelist.push(m);
      });
    }
    return machinelist;
  }

  async getMachineListForCompletedJob(): Promise<ScheduleTracker[]> {
    let scheduleList: ScheduleTracker[] = [];

    const snapshot = await this.db.database.ref('Machine').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (!childSnapshot.key.includes('@@')) {
          const m = new Machine();
          m.Machine_No = childSnapshot.key;

          childSnapshot.child("Schedule").forEach(childSnapshot2 => {
            if(childSnapshot2.child("_Machine_Schedule_Status").val() === 'Done'){
              let schedule_track = new ScheduleTracker;

              schedule_track.Machine_Number = m.Machine_No;
              schedule_track.Machine_Schedule_No = childSnapshot2.key;

              schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
              schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
              schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
              schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
              schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
              schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
              schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
              schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
              schedule_track.Machine_Schedule_Status =childSnapshot2.child("_Machine_Schedule_Status").val();
              schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
              schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
              schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();
              schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val();
              schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val();
              // schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();

              this.db.database.ref('LogFile').child(schedule_track.Machine_Number).child(schedule_track.Machine_Schedule_No).once('value').then(downtimeSnapshot=>{
                  if(downtimeSnapshot.exists()){
                    let duration = 0;
                    downtimeSnapshot.forEach(downtime=>{
                      let detail ={
                        InterruptCode: downtime.child('Interrupt Code').val(),
                        RestoredTime:  new Date(downtime.child('RestoredTime').val()),
                        StoppedTime: new Date(downtime.child('StoppedTime').val()),
                      }
                      if(downtime.child('Status').val() === 'RESOLVED'){
                        duration += (( Math.abs(detail.RestoredTime.getTime() - detail.StoppedTime.getTime())) / 3600000 );
                        schedule_track.TotalDownTime = duration;
                        schedule_track.DownTimeDetail.push(detail);
                      }
                    })
                  }else{
                    schedule_track.TotalDownTime = 0;
                  }
              })



              this.getPart(schedule_track);
              scheduleList.push(schedule_track)
            }
          });
        }
      });
    }
    return scheduleList;
  }

  async getPart(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(schedule.Machine_Schedule_Part_No).once('value');

    if (snapshot.exists()) {
      schedule.PartName = snapshot.child("Part Name").val();
      schedule.OpeningStockPart = snapshot.child('Stock Quantity').val();
      schedule.PartCavityNum = snapshot.child('Cavities').val();
      schedule.CycleTime = snapshot.child('Cycle Time').val();

      if((schedule.Exp_Qty - schedule.Acc_Qty) <=0)
        schedule.HourLeft = 0;
      else
        schedule.HourLeft = parseFloat(schedule.PredefinedCycleTime) * ((schedule.Exp_Qty - schedule.Acc_Qty)/ parseFloat(schedule.PartCavityNum)) /3600;
    }
  }




  UpdateMachineList(snapshot: DataSnapshot): Machine[] {
    let Machine_list: Machine[] = [];
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let machine = new Machine();
        machine.Machine_No = childSnapshot.key;
        machine.Machine_Code = childSnapshot.child("Code").val();
        machine.MachineTonnage = childSnapshot.child("Tonnage").val();
        // machine._statusColor = childSnapshot.child("Accumulate Quantity").val();
        // machine._taskInProgress = childSnapshot.child("Expected Quantity").val();
        var schedule_TrackList: ScheduleTracker[] = [];
        childSnapshot.child("Schedule").forEach((function (childSnapshot2) {
          let schedule_track = new ScheduleTracker;
          schedule_track.Machine_Schedule_No = childSnapshot2.key;

          schedule_track.Machine_Schedule_Code = childSnapshot2.child("Code").val();
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_TrackList.push(schedule_track);
        }));
        machine.Schedule_Track = schedule_TrackList;
        Machine_list.push(machine);
      }));
    }
    return Machine_list;
  }

  RT_UpdateMachineList(snapshot: DataSnapshot): Machine[] {
    let Machine_list: Machine[] = [];
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let machine = new Machine();
        machine.Machine_No = childSnapshot.key;
        machine.Machine_Code = childSnapshot.child("Code").val();
        machine.MachineStatus = childSnapshot.child("Machine Status").val();
        machine.MachineTonnage = childSnapshot.child("Tonnage").val();

        var schedule_TrackList: ScheduleTracker[] = [];

        childSnapshot.child("Schedule").forEach((function (childSnapshot2) {
          let schedule_track = new ScheduleTracker;

          schedule_track.Machine_Schedule_No = childSnapshot2.key;
          schedule_track.Machine_Schedule_Code = childSnapshot2.child("_Machine_Schedule_Code").val();
          schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("_Machine_Schedule_PO_No").val();
          schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("_Machine_Schedule_Part_No").val();
          schedule_track.Acc_Qty = childSnapshot2.child("_Acc_Qty").val();
          schedule_track.Exp_Qty = childSnapshot2.child("_Exp_Qty").val();
          schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("_Machine_Schedule_Start_Date").val();
          schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("_Machine_Schedule_End_Date").val();
          schedule_track.DateFormatStart = new Date(childSnapshot2.child("_Machine_Schedule_Start_Date").val());
          schedule_track.DateFormatEnd = new Date(childSnapshot2.child("_Machine_Schedule_End_Date").val());
          schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
          schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
          schedule_track.POQuantity = childSnapshot2.child("POQuantity").val();
          schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
          schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val() || 0;
          schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val() || 0;
          schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
          schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
          schedule_track.TotalDownTime = childSnapshot2.child("_TotalDownTime").val();
          schedule_track.Availability = childSnapshot2.child("_Availability").val();
          schedule_track.Performance = childSnapshot2.child("_Performance").val();
          schedule_track.Effectiveness = childSnapshot2.child("_Effectiveness").val();

          if (schedule_track.Machine_Schedule_Status != "Done" && schedule_track.Acc_Qty == schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }
          var year = schedule_track.Machine_Schedule_Start_Date.substring(0, 4);
          var month = schedule_track.Machine_Schedule_Start_Date.substring(5, 7);
          if (schedule_track.Machine_Schedule_Start_Date.substring(5, 6) == "0") {
            month = schedule_track.Machine_Schedule_Start_Date.substring(6, 7);
          }
          var day = schedule_track.Machine_Schedule_Start_Date.substring(8, 10);
          if (schedule_track.Machine_Schedule_Start_Date.substring(8, 9) == "0") {
            day = schedule_track.Machine_Schedule_Start_Date.substring(9, 10);
          }
          var currentDate = new Date();
          var currentMonth = currentDate.getMonth() + 1;
          schedule_TrackList.push(schedule_track);
        }));
        machine.Schedule_Track = schedule_TrackList;
        Machine_list.push(machine);
      }));
    }
    return Machine_list;
  }

  get_CurrentRunningSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let CurrentRunning_schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {
        CurrentRunning_schedule = Schdule_TrackList[i];
        return CurrentRunning_schedule;
      }
    }
    return CurrentRunning_schedule;
  }
  get_WaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_Schedule: ScheduleTracker;
    let myDate = new Date();
    /*
    for(var i = 0; i < Schdule_TrackList.length ; i++){
        if(Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")
          && ){
            Waiting_Schedule = Schdule_TrackList[i];
            return Waiting_Schedule;
        }
    }
    */
    return Waiting_Schedule;
  }

  async getMachineListWithLastSchedule(): Promise<Machine[]> {
    let Machine_list: Machine[] = [];
    var snapshot = await this.db.database.ref('Machine').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let machine = new Machine();
        machine.Machine_No = childSnapshot.key;
        machine.Machine_Code = childSnapshot.child("Code").val();
        machine.MachineStatus = childSnapshot.child("Machine Status").val();
        machine.MachineTonnage = childSnapshot.child("Tonnage").val();
        var schedule_TrackList: ScheduleTracker[] = [];
        var list = []
        childSnapshot.child("Schedule").forEach((function (childSnapshot2) {
          list.push({
            ...childSnapshot2.val(),
            key: childSnapshot2.key
          })
        }));
        list = list.sort((a, b) => {
          return this.compare(new Date(a.endAt).getTime(), new Date(b.endAt).getTime(), false)
        })
        if (list.length > 0) {
          let schedule_track = new ScheduleTracker;
          schedule_track.Machine_Schedule_No = list[0].key;
          schedule_track.Machine_Schedule_Code = list[0]["Code"];
          schedule_track.Machine_Schedule_PO_No = list[0]["PO No"];
          schedule_track.Machine_Schedule_Part_No = list[0]["Part No"];
          schedule_track.Acc_Qty = list[0]["Accumulate Quantity"];
          schedule_track.Exp_Qty = list[0]["Expected Quantity"];
          schedule_track.Machine_Schedule_Start_Date = list[0]["startAt"];
          schedule_track.Machine_Schedule_End_Date = list[0]["endAt"];
          schedule_track.DateFormatStart = new Date(list[0]["startAt"]);
          schedule_track.DateFormatEnd = new Date(list[0]["endAt"]);
          schedule_track.PredefinedCycleTime = list[0]["Actual Cycle Time"];
          schedule_track.DatePresettingStart = list[0]["presetStart"];
          schedule_track.DatePresettingEnd = list[0]["presetEnd"];
          schedule_track.DateDown_Start = list[0]["downStart"];
          schedule_track.DateDown_End = list[0]["downEnd"];
          schedule_track.TotalDownTime = list[0]["Total Down Time"];
          schedule_track.Availability = list[0]["Availability"];
          schedule_track.Performance = list[0]["Performance"];
          schedule_track.Effectiveness = list[0]["Effectiveness"];
          schedule_track.Machine_Schedule_Status = list[0]["_Machine_Schedule_Status"];
          if (schedule_track.Machine_Schedule_Status != "Done" && schedule_track.Acc_Qty == schedule_track.Exp_Qty) {
            schedule_track.Machine_Schedule_Status = "Done";
          }
          schedule_TrackList.push(schedule_track);
        }
        machine.Schedule_Track = schedule_TrackList;
        Machine_list.push(machine);
      });
    }
    return Machine_list;
  }

  async getMachineWithLastSchedule(machineNum): Promise<Machine[]> {
    let Machine_list: Machine[] = [];
    var snapshot = await this.db.database.ref('Machine').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let machine = new Machine();
        if (machineNum == childSnapshot.key) {
          machine.Machine_No = childSnapshot.key;
          machine.Machine_Code = childSnapshot.child("Code").val();
          machine.MachineStatus = childSnapshot.child("Machine Status").val();
          machine.MachineTonnage = childSnapshot.child("Tonnage").val();
          var schedule_TrackList: ScheduleTracker[] = [];
          var list = []
          childSnapshot.child("Schedule").forEach((function (childSnapshot2) {
            list.push({
              ...childSnapshot2.val(),
              key: childSnapshot2.key
            })
          }));
          list = list.sort((a, b) => {
            return this.compare(new Date(a.endAt).getTime(), new Date(b.endAt).getTime(), false)
          })
          if (list.length > 0) {
            let schedule_track = new ScheduleTracker;
            schedule_track.Machine_Schedule_No = list[0].key;
            schedule_track.Machine_Schedule_Code = list[0]["Code"];
            schedule_track.Machine_Schedule_PO_No = list[0]["PO No"];
            schedule_track.Machine_Schedule_Part_No = list[0]["Part No"];
            schedule_track.Acc_Qty = list[0]["Accumulate Quantity"];
            schedule_track.Exp_Qty = list[0]["Expected Quantity"];
            schedule_track.Machine_Schedule_Start_Date = list[0]["startAt"];
            schedule_track.Machine_Schedule_End_Date = list[0]["endAt"];
            schedule_track.DateFormatStart = new Date(list[0]["startAt"]);
            schedule_track.DateFormatEnd = new Date(list[0]["endAt"]);
            schedule_track.PredefinedCycleTime = list[0]["Actual Cycle Time"];
            schedule_track.DatePresettingStart = list[0]["presetStart"];
            schedule_track.DatePresettingEnd = list[0]["presetEnd"];
            schedule_track.DateDown_Start = list[0]["downStart"];
            schedule_track.DateDown_End = list[0]["downEnd"];
            schedule_track.TotalDownTime = list[0]["Total Down Time"];
            schedule_track.Availability = list[0]["Availability"];
            schedule_track.Performance = list[0]["Performance"];
            schedule_track.Effectiveness = list[0]["Effectiveness"];
            schedule_track.Machine_Schedule_Status = list[0]["_Machine_Schedule_Status"];
            if (schedule_track.Machine_Schedule_Status != "Done" && schedule_track.Acc_Qty == schedule_track.Exp_Qty) {
              schedule_track.Machine_Schedule_Status = "Done";
            }
            schedule_TrackList.push(schedule_track);
          }
          machine.Schedule_Track = schedule_TrackList;
          Machine_list.push(machine);
        }
      });
    }
    return Machine_list;
  }
  setMachine_Logfile(machineNum: String, scheduleID: String, code: String) {
    let logfile = {};
    logfile['/StoppedTime'] = Date.now();
    logfile['/Interrupt Code'] = code;
    logfile['/Status'] = "UNSOLVE"
    let logId = this.db.database.ref('LogFile/' + machineNum + "/" + scheduleID).push().key;
    this.db.database.ref('LogFile/' + machineNum + "/" + scheduleID + "/" + logId).update(logfile);
  }
  async Machine_restoreLogState(machineNum: String, scheduleID: String) {
    var snapshot = await this.db.database.ref('LogFile/' + machineNum + "/" + scheduleID).once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (String(childSnapshot.child("Status").val()).match("UNSOLVE")) {
          let logfile = {};
          logfile['/RestoredTime'] = Date.now();
          logfile['/Status'] = "RESOLVED"
          this.db.database.ref('LogFile/' + machineNum + "/" + scheduleID + "/" + childSnapshot.key).update(logfile);
        }
      })
    }
  }
  async getUnsolveMachineStatus(machineNum: String): Promise<Boolean> {
    var snapshot = await this.db.database.ref('Machine/' + machineNum + '/Logfile').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (String(childSnapshot.child("Status").val()).match("UNSOLVE")) {
          return true;
        }
      })
    }
    return false;
  }
  async setMachineStopped_Code(machineNum: String, scheduleID: String, machineCode: String) {
    var snapshot = await this.db.database.ref('Machine/' + machineNum + '/Logfile').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (String(childSnapshot.child("Status").val()).match("UNSOLVE")) {
          let logfile = {};
          logfile['/Code'] = machineCode;
          this.db.database.ref('Machine/' + machineNum + '/Logfile/' + childSnapshot.key).update(logfile);
          this.db.database.ref('Machine/' + machineNum + '/Schedule/' + scheduleID + "/Logfile/" + childSnapshot.key).update(logfile);
        }
      })
    }
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  updatePresetStartTime(_newPO: any) {
    let updates = {};
    updates[_newPO.machineChosen + '/Schedule/' + _newPO.scheduleID + '/presetStart'] = _newPO.startTimer;
    this.db.database.ref('Machine/').update(updates);
  }
  updatePresetEndTime(_newPO: any) {
    let updates = {};
    updates[_newPO.machineChosen + '/Schedule/' + _newPO.scheduleID + '/presetEnd'] = _newPO.endTimer;
    this.db.database.ref('Machine/').update(updates);
  }

  getMachine(snapshot): Machine {
    let machine = new Machine();
    if (snapshot.exists()) {
      machine.Machine_No = snapshot.key;
      machine.Machine_Code = snapshot.child("Code").val();
      machine.MachineStatus = snapshot.child("Machine Status").val();
      machine.MachineTonnage = snapshot.child("Tonnage").val();
      let schedule_TrackList: ScheduleTracker[] = [];
      snapshot.child("Schedule").forEach(childSnapshot2 => {
        let schedule_track = new ScheduleTracker;
        schedule_track.Machine_Schedule_No = childSnapshot2.key;
        schedule_track.Machine_Schedule_No2 = childSnapshot2.child('LinkedPart2ScheduleID').val();
        schedule_track.Machine_Schedule_No3 = childSnapshot2.child('LinkedPart3ScheduleID').val()
        schedule_track.Machine_Schedule_Code = childSnapshot2.child("Code").val();
        schedule_track.Machine_Schedule_PO_No = childSnapshot2.child("PO No").val();
        schedule_track.Machine_Schedule_Part_No = childSnapshot2.child("Part No").val();
        schedule_track.Acc_Qty = childSnapshot2.child("Accumulate Quantity").val();
        schedule_track.Exp_Qty = childSnapshot2.child("Expected Quantity").val();
        schedule_track.Machine_Schedule_Start_Date = childSnapshot2.child("startAt").val();
        schedule_track.Machine_Schedule_End_Date = childSnapshot2.child("endAt").val();
        schedule_track.DateFormatStart = new Date(childSnapshot2.child("startAt").val());
        schedule_track.DateFormatEnd = new Date(childSnapshot2.child("endAt").val());
        schedule_track.LastUpdate = new Date(childSnapshot2.child("Latest Update Time").val() * 1000);
        schedule_track.Machine_Schedule_Status = childSnapshot2.child("_Machine_Schedule_Status").val();
        schedule_track.POQuantity = childSnapshot2.child("PO Quantity").val();
        schedule_track.DatePresettingStart = childSnapshot2.child("presetStart").val();
        schedule_track.DatePresettingEnd = childSnapshot2.child("presetEnd").val();
        schedule_track.Availability = childSnapshot2.child("Availability").val();
        schedule_track.Performance = childSnapshot2.child("Performance").val();
        schedule_track.Effectiveness = childSnapshot2.child("Effectiveness").val();
        schedule_track.DateDown_Start = childSnapshot2.child("downStart").val();
        schedule_track.DateDown_End = childSnapshot2.child("downEnd").val();
        if (schedule_track.Machine_Schedule_Status !== "Done" && schedule_track.Acc_Qty === schedule_track.Exp_Qty) {
          schedule_track.Machine_Schedule_Status = "Done";
        }
        schedule_track.PredefinedCycleTime = childSnapshot2.child("Actual Cycle Time").val();
        var currentDate = new Date();
        if (schedule_track.DateFormatEnd.getTime() >= currentDate.getTime()) {
          schedule_TrackList.push(schedule_track);
        }
        schedule_track.Machine_Progress = (schedule_track.Acc_Qty / schedule_track.Exp_Qty) * 100;
        this.getPart(schedule_track);
      });
      machine.Schedule_Track = schedule_TrackList;
    }
    return machine;
  }

  updateJobOEE(_newPO: any) {
    let updates = {};
      updates[_newPO.machineChosen + '/Schedule/' + _newPO.scheduleID + '/Availability'] = _newPO.availability;
      updates[_newPO.machineChosen + '/Schedule/' + _newPO.scheduleID + '/Performance'] = _newPO.performance;
      updates[_newPO.machineChosen + '/Schedule/' + _newPO.scheduleID + '/endAt'] = new Date();

    this.db.database.ref('Machine/').update(updates);
    this.db.database.ref('Purchase Order/'+_newPO.po +'/Part List/'+_newPO.scheduleID + '/Status').set('Done')
    this.db.database.ref('Purchase Order/'+_newPO.po +'/Part List/'+_newPO.scheduleID + '/PIC').set(_newPO.pic)



  }

}
