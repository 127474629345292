import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {

  Customer = new Customer();
  currencyList = Currency;

  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<AddCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data){
        this.Customer.Customer_ID = data._Customer_ID;
        this.Customer.Customer_Name = data._Customer_Name;
        this.Customer.Contact = data._Contact;
        this.Customer.Email = data._Email;
        this.Customer.Address = data._Address;
        this.Customer.Last_Updated = data._Last_Updated;
        this.Customer.Created_Date = data._Created_Date;
        this.Customer.Updated_By = data._Updated_By;
        this.Customer.Created_By = data._Created_By;
        this.Customer.Person_In_Charge = data._Person_In_Charge;
        this.Customer.Currency = data._Currency;
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.Customer);
  }

  emailFlag= false;
  phoneFlag= false;

  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  phoneChange(event,model:NgModel){

    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

}
