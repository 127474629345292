import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { StocksComponent } from '../../parts/PopUpModal/stocks/stocks.component';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.css']
})
export class StaffDetailsComponent implements OnInit {


  job: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<StocksComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private changeDef: ChangeDetectorRef

  ) {
    if(data.PO_Staff_List.length > 0){
      data.PO_Staff_List.forEach(async (element, index) => {
        this.job.push(element);
      });
    }
    else{
      this.job.push(null);
    }
  }
  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }



}
