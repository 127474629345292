import { POStock } from './../Object_Classes/Part/Part';
import { AngularFireStorage } from '@angular/fire/storage';
import { RawMaterialInfo } from './../Object_Classes/RawMaterial/RawMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../Object_Classes/Part/Part';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';


export class PartDB_controller {
  constructor(private db: AngularFireDatabase,private storage:AngularFireStorage, private firestore: AngularFirestore) {
  }
  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getPartList(): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');
    //var snapshotPO = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let part = new Part();

        part.Part_No = childSnapshot.key;
        part.Part_Name = childSnapshot.child('Part Name').val();
        part.Cycle_Time = childSnapshot.child('Cycle Time').val();
        part.Number_Cavity = childSnapshot.child('Cavities').val();
        part.Available_Machine = childSnapshot.child('Available Machine').val();
        part.Part_Weight = childSnapshot.child('Part Weight').val();
        part.Wastage = childSnapshot.child('Wastage').val();
        part.Material_Code = childSnapshot.child('Material Code').val();
        part.Colour_Code = childSnapshot.child('Colour Code').val();
        part.Colour_Ratio = childSnapshot.child('Colour Ratio').val();
        part.Packing_Quantity = childSnapshot.child('Packing Quantity').val();
        part.Packing_Quantity_Per_Carton = childSnapshot.child('Packing Bag Per Carton').val();
        part.Product_Weight = childSnapshot.child('Product Weight').val();
        part.Product_Width = childSnapshot.child('Product Width').val();

        PartList.push(part);
      });
    }


    return PartList;
  }

  async add_or_update_Part(_newPart: Part) {
    var updates = {};
    
    await this.db.database.ref('PartRawMaterial').set(null).then(async r => {
      const partinfo = { }

      if(_newPart.Stock_Quantity){
        partinfo['Stock Quantity'] = _newPart.Stock_Quantity;
      }
      else{
        partinfo['Stock Quantity'] = 0;
      }

      partinfo['Part Name'] = _newPart.Part_Name || '-';
      partinfo['Cycle Time'] = _newPart.Cycle_Time || '-';
      partinfo['Cavities'] = _newPart.Number_Cavity || '-';
      partinfo['Available Machine'] = _newPart.Available_Machine || '-';
      partinfo['Part Weight'] = _newPart.Part_Weight || 0;
      partinfo['Wastage'] = _newPart.Wastage || 0;
      partinfo['Material Code'] = _newPart.Material_Code || '-';
      partinfo['Colour Code'] = _newPart.Colour_Code || '-';
      partinfo['Colour Ratio'] = _newPart.Colour_Ratio || 0;
      partinfo['Packing Quantity'] = _newPart.Packing_Quantity || 0;
      partinfo['Packing Bag Per Carton'] = _newPart.Packing_Quantity_Per_Carton || '-';
      partinfo['Product Weight'] = _newPart.Product_Weight || 0;
      partinfo['Product Width'] = _newPart.Product_Width || 0;

      await this.db.database.ref('Part/' + _newPart.Part_No).set(partinfo)

      const custinfo ={}

      custinfo['Customer Name'] = _newPart.Customer_Name || '-';
      custinfo['PO No'] = _newPart.PO_No || '-';
      await this.db.database.ref('Part/' + _newPart.Part_No +'/Customer Preference').set(custinfo)


      const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
      const info = {
        date: new Date(),
        content:JSON.stringify(_newPart),
      };

      await this.firestore.collection('PartLog').doc(dateFormat).set({ Date: new Date() });
      await this.firestore.collection('PartLog').doc(dateFormat).collection('Part').add(info);
        if (typeof (_newPart.DrawingURL) !== 'string' && typeof (_newPart.PhotoURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.DrawingURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(url => {
                if (url) {
                  _newPart.DrawingURL = url;
                  if (_newPart.PhotoURL) {
                    n = Date.now();
                    filePath = `PartsImages/${n}`;
                    fileRef = this.storage.ref(filePath);
                    const task2 = this.storage.upload(`PartsImages/${n}`, _newPart.PhotoURL);
                    task2.snapshotChanges().pipe(finalize(() => {
                      var downloadURL2 = fileRef.getDownloadURL();
                      downloadURL2.subscribe(async url2 => {
                        _newPart.PhotoURL = url2;
                        const photo = {}
                        photo['DrawingURL'] = _newPart.DrawingURL;
                        photo['PhotoURL'] = _newPart.PhotoURL;
                        await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);

                      })
                    }))
                      .subscribe(url => {
                        if (url) {
                          console.log(url);
                        }
                      });
                  }
                }
              });
            }
            )

          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        }
        else if (typeof (_newPart.DrawingURL) !== 'string' && typeof (_newPart.PhotoURL) === 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.DrawingURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  _newPart.DrawingURL = url;

                  const photo = {}
                  photo['DrawingURL'] = _newPart.DrawingURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);

                }
              });
            }
            )

          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        }
        else if (typeof (_newPart.DrawingURL) === 'string' && typeof (_newPart.PhotoURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.PhotoURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  _newPart.PhotoURL = url;
                  const photo = {}
                  photo['PhotoURL'] = _newPart.PhotoURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
                }
              });
            }
            )

          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        } 
        else {

          const photo = {}
          photo['PhotoURL'] = _newPart.PhotoURL;
          photo['DrawingURL'] = _newPart.DrawingURL;
          await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
        }

    })
  }

  async search_Part(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var childSnapshot = await this.db.database.ref('Part/'+Part_No).once('value');
    
    if (childSnapshot.exists()) {
      var get_Part_No = childSnapshot.key;

      if (Part_No == get_Part_No) {
        searched_part.Part_No = childSnapshot.key;
        searched_part.Part_Name = childSnapshot.child('Part Name').val();
        searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
        searched_part.Number_Cavity = childSnapshot.child('Cavities').val();
        searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
        searched_part.Part_Weight = childSnapshot.child('Part Weight').val();
        searched_part.Wastage = childSnapshot.child('Wastage').val();
        searched_part.Material_Code = childSnapshot.child('Material Code').val();
        searched_part.Colour_Code = childSnapshot.child('Colour Code').val();
        searched_part.Colour_Ratio = childSnapshot.child('Colour Ratio').val();
        searched_part.Packing_Quantity = childSnapshot.child('Packing Quantity').val();
        searched_part.Packing_Quantity_Per_Carton = childSnapshot.child('Packing Bag Per Carton').val();
        searched_part.Product_Weight = childSnapshot.child('Product Weight').val();
        searched_part.Product_Width = childSnapshot.child('Product Width').val();
      }
    }

    return searched_part;
  }

  async search_Partial_Part(Part_No: string): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_part = new Part();
        var get_Part_No = childSnapshot.key;

        if (get_Part_No.includes(Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Part_Name = childSnapshot.child('Part Name').val();
          searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
          searched_part.Number_Cavity = childSnapshot.child('Cavities').val();
          searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
          searched_part.Part_Weight = childSnapshot.child('Part Weight').val();
          searched_part.Wastage = childSnapshot.child('Wastage').val();
          searched_part.Material_Code = childSnapshot.child('Material Code').val();
          searched_part.Colour_Code = childSnapshot.child('Colour Code').val();
          searched_part.Colour_Ratio = childSnapshot.child('Colour Ratio').val();
          searched_part.Packing_Quantity = childSnapshot.child('Packing Quantity').val();
          searched_part.Packing_Quantity_Per_Carton = childSnapshot.child('Packing Bag Per Carton').val();
          searched_part.Product_Weight = childSnapshot.child('Product Weight').val();
          searched_part.Product_Width = childSnapshot.child('Product Width').val();

          PartList.push(searched_part);
        }
      }));
    }

    return PartList;
  }


  delete_Part(part_no: string) {
    this.db.database.ref('/Part/' + part_no).set(null);
  }


  async getMaterial(id): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('RawMaterial').child(id).once('value');
    if (snapshot.exists()) {
        searched_raw.Material_ID = snapshot.key;
        searched_raw.Material_Name = snapshot.child('_Material_Name').val();
        searched_raw.Material_Color = snapshot.child('_Material_Color').val();
        searched_raw.Material_Color_Code = snapshot.child('_Material_Color_Code').val();
        searched_raw.Material_Color_Ratio = snapshot.child('_Material_Color_Ratio').val();
        searched_raw.Material_Spen = snapshot.child('_Material_Spen').val();
        searched_raw.In_Stock = snapshot.child('_In_Stock').val();
        searched_raw.Quantity_Reserved = snapshot.child('_Quantity_Reserved').val();
        searched_raw.Unit = snapshot.child('_Unit').val();
        searched_raw.Quantity_PerBag = snapshot.child('_Quantity_PerBag').val();
        searched_raw.Unit_Price = snapshot.child('_Unit_Price').val();
        searched_raw.Updated_By = snapshot.child('_Updated_By').val();
        searched_raw.Created_By = snapshot.child('_Created_By').val();
        searched_raw.Created_Date = new Date(snapshot.child('_Created_Date').val());
        searched_raw.Last_Updated = new Date(snapshot.child('_Last_Updated').val());
        searched_raw.Raw_Type = snapshot.child('_Raw_Type').val();
        searched_raw.Carton_Weight = snapshot.child('_Carton_Weight').val();
    }
    return searched_raw;
  }

  /*async getSupplier(part:Part, supplierId, number): Promise<void>{
    var snapshot = await this.db.database.ref('Supplier').child(supplierId).once('value');
    if(snapshot.exists()){
      switch(number){
        case 1:part.Supplier1 = snapshot.child('_Supplier_Name').val();break;
        case 4:part.Supplier4 = snapshot.child('_Supplier_Name').val();break;
        case 5:part.Supplier5 = snapshot.child('_Supplier_Name').val();break;
        case 6:part.Supplier6 = snapshot.child('_Supplier_Name').val();break;
      }
    }
  }*/

  async getCustomer(part:Part, customerId): Promise<void>{
    var snapshot = await this.db.database.ref('Customer').child(customerId).once('value');
    if(snapshot.exists()){
      part.Customer_Name = snapshot.child('_Customer_Name').val();
      part.Customer_Address = snapshot.child('_Address').val();
    }
  }

  async search_PartWithStock(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( childSnapshot=>{
        var get_Part_No = childSnapshot.key;
        if (Part_No.match(get_Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          const postocks : POStock[] = [];
          childSnapshot.child('Stock').forEach(child=>{
            const stock = new POStock();
            stock.BoxNumber = child.key;
            stock.Quantity = child.val();
            postocks.push(stock)
          })
          searched_part.POStocks = postocks;
        }
      });
    }

    return searched_part;
  }


}
