export class Staff {
  public StaffName = '';
  public Role = '';
  public Email = '';
  public StaffNo = '';
  public StaffID = null;
  public Access = [];
  public UpdatedBy = '';
  public CreatedBy = '';
  public UpdatedDate: Date;
  public CreatedDate: Date;
  public password = '';

}
