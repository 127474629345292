import { StaffComponent } from './Components/staff/staff.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { ReceiptComponent } from './Components/receipt/receipt.component';
import { MoldComponent } from './Components/mold/mold.component';
import { RawComponent } from './Components/raw/raw.component';
import { ScheduleComponent } from './Components/schedule/schedule.component';
import { PurchaseOrderComponent } from './Components/purchaseOrder/purchaseOrder.component';
import { PartsComponent } from './Components/parts/parts.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { LayoutComponent } from './Components/Home/layout/layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/Home/login/login.component';
import { MonitorComponent } from './Components/monitor/monitor.component';
import { SchedulingComponent } from './Components/scheduling/scheduling.component';
import { ShippingComponent } from './Components/shipping/shipping.component';
import { QCComponent } from './Components/qc/qc.component';
import { InvoiceComponent } from './Components/invoice/invoice.component';


const routes: Routes = [{
  path: '',
  redirectTo: '/login',
  pathMatch: 'full',
},
{ path: 'login', component: LoginComponent },
{ path: '404', component:  NotFoundComponent},

{ path: 'home', component: LayoutComponent,
children: [
  {
    path: 'Dashboard',
    component: DashboardComponent
  },
  {
    path: 'MachineMonitoring',
    component: MonitorComponent

  },
  {
    path: 'operator',
    component: ScheduleComponent
  },
  {
    path: 'Scheduling',
    component: SchedulingComponent
  },
  {
    path: 'PartManagement',
    component: PartsComponent
  },

  {
    path: 'POManagement',
    component: PurchaseOrderComponent
  },
  {
    path: 'QualityandQuantityControl',
    component: QCComponent
  },

  {
    path: 'InvoiceManagement',
    component: InvoiceComponent
  },
  {
    path: 'RawMaterialStockManagement',
    component: RawComponent
  },
  {
    path: 'MoldManagement',
    component: MoldComponent
  },
  {
    path: 'ReceiptManagement',
    component: ReceiptComponent
  },
  {
    path: 'StaffManagement',
    component: StaffComponent
  },

]
},
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
