import { ConfirmationDialogComponent } from './../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnChanges, NgZone, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MachineDB_controller } from '../../Services/DB_Controller/MachineDB_controller';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { WeightingDB_controller } from '../../Services/DB_Controller/WeightingDB_controller';
import { Machine, RawMaterialUsed, ScheduleTracker } from '../../Services/Object_Classes/Machine/Machine';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import { FinishDialogComponent } from 'src/app/Shared/finish-dialog/finish.component';
import { AlignDialogComponent } from 'src/app/Shared/align-dialog/align-dialog.component';
import { PoDialogComponent } from 'src/app/Shared/po-dialog/po-dialog.component';
import { PinDialogComponent } from 'src/app/Shared/pin-dialog/pin-dialog.component';
import { StaffDialogComponent } from 'src/app/Shared/staff-dialog/staff-dialog.component';
import { DetailsScheduleComponent } from './PopUpSchedule/details-schedule/details-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { StaffInfo } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})


export class ScheduleComponent implements OnInit {
  Machine_No: string;
  Schedule_Track: SchedulerComponent[];
  matSelectPart: Part[] = [];
  clone: Part[] = [];
  selectedPart: string = "";
  rejectedReasons: string[] = [];
  rejectedTallies: number[] = [];
  staffIds: StaffInfo[] = [];
  now: number;
  rejectTally: number;
  is: any;
  poNumber: any;
  startTimer: any;
  endTimer: any;
  productionQuantity: any;
  subscribe: any;
  CodeSubmitted_flag: Boolean = false;
  Display_flag: Boolean = false;
  Button_flag: Boolean = false;
  AlertTimer: any;
  AlertCountDown: String;
  Schedule_Part_No: String;
  rejectMessage: String = "-";
  staffMessage: String = "-";
  totalReject: Number = 0;
  machinedb_Controller = new MachineDB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db, this.storage, this.firestore);
  weighting_Controller = new WeightingDB_controller(this.db);
  PODB_controller: PODB_controller = new PODB_controller(this.db);
  flaskServertimerInterval: any;
  DatetimerInterval: any;
  ViewUpcoming = false;
  iss: any;
  machineFailureStatus = true;
  Presetting_flag: Boolean = false;
  Reason_flag: Boolean = false;
  Machine: Machine = new Machine();
  personInCharge: string;
  photo: string = '';
  mould: string = "";

  InterruptCodeList = [
    {
      value: 'MUA',
      ViewValue: 'Machine under (Adjusment)',
      Color: '#ffcc99',
    },
    {
      value: 'MUS',
      ViewValue: 'Machine under (Setting)',
      Color: '#ffcc99',
    },
    {
      value: 'MPE',
      ViewValue: 'Machine Problem (Electrical)',
      Color: '#45a8ff',
    },
    {
      value: 'MPH',
      ViewValue: 'Machine Problem (Hydraulic)',
      Color: '#45a8ff',
    },

    {
      value: 'MPP',
      ViewValue: 'Machine Problem (Pneumatic)',
      Color: '#45a8ff',
    },
    {
      value: 'MPPD',
      ViewValue: 'Machine Problem (Parts damage)',
      Color: '#45a8ff',
    },
    {
      value: 'SCM',
      ViewValue: 'Mould set up (Change mould)',
      Color: '#c369ff',
    },
    {
      value: 'SCS',
      ViewValue: 'Mould set up (Change slider)',
      Color: '#c369ff',
    },
    {
      value: 'SCC',
      ViewValue: 'Mould set up (Change core)',
      Color: '#c369ff',
    },
    {
      value: 'MM',
      ViewValue: 'Mould problem (Manifold)',
      Color: '#fff36e',
    },
    {
      value: 'MHC',
      ViewValue: 'Mould problem (Manifold heater controller)',
      Color: '#fff36e',
    },
    {
      value: 'MCS',
      ViewValue: 'Mould problem (Cooling system)',
      Color: '#fff36e',
    },
    {
      value: 'MPD',
      ViewValue: 'Mould problem (Mould parts damage)',
      Color: '#fff36e',
    },
    {
      value: 'MS',
      ViewValue: 'Mould problem (Mould Service)',
      Color: '#fff36e',
    },
    {
      value: 'MTF',
      ViewValue: 'Material problem (Finish)',
      Color: '#ff829b',
    },
    {
      value: 'MMX',
      ViewValue: 'Material problem (Mistake mix)',
      Color: '#ff829b',
    },
    {
      value: 'HD',
      ViewValue: 'Material problem (Hopper dryer)',
      Color: '#ff829b',
    },
    {
      value: 'CC',
      ViewValue: 'Material problem (Change colour)',
      Color: '#ff829b',
    },
    {
      value: 'MB',
      ViewValue: 'Material problem (Material block)',
      Color: '#ff829b',
    },
    {
      value: 'NL',
      ViewValue: 'Material problem (Nozzle leaking)',
      Color: '#ff829b',
    },
    {
      value: 'SM',
      ViewValue: 'Sink mark',
      Color: '#7ffd4',
    },
    {
      value: 'SS',
      ViewValue: 'Silver streak',
      Color: '#ffa500',
    },
    {
      value: 'WM',
      ViewValue: 'White mark',
      Color: '#c0c0c0',
    },
    {
      value: 'BM',
      ViewValue: 'Burn mark',
      Color: '#800000',
    },
    {
      value: 'WT',
      ViewValue: 'Wall thickness',
      Color: '#add8e6',
    },
    {
      value: 'BL',
      ViewValue: 'Bubble',
      Color: '#008000',
    },
    {
      value: 'RP',
      ViewValue: 'Robot arm problem',
      Color: '#ffff00',
    },
    {
      value: 'RE',
      ViewValue: 'Robot arm problem (Electrical)',
      Color: '#ffff00',
    },
    {
      value: 'RPD',
      ViewValue: 'Robot arm problem (Part damage)',
      Color: '#ffff00',
    },
    {
      value: 'MNT',
      ViewValue: 'Machine maintenance',
      Color: '#ffc0cb',
    },
    {
      value: 'NJO',
      ViewValue: 'No job order',
      Color: '#00ffff',
    },
    {
      value: 'OT',
      ViewValue: 'Others',
      Color: '#808000',
    }
  ];

  rejectCodeList = [
    {
      value: 'BLACK DOT',
      ViewValue: 'BLACK DOT'
    },
    {
      value: 'WALL THICKNESS',
      ViewValue: 'WALL THICKNESS'
    },
    {
      value: 'WATER MARK',
      ViewValue: 'WATER MARK'
    },
    {
      value: 'FLOW LINE',
      ViewValue: 'FLOW LINE'
    },
    {
      value: 'JOINT LINE',
      ViewValue: 'JOINT LINE'
    },
    {
      value: 'SHARP EDGE',
      ViewValue: 'SHARP EDGE'
    },
    {
      value: 'STUCK',
      ViewValue: 'STUCK'
    },
    {
      value: 'CUT UNEVEN',
      ViewValue: 'CUT UNEVEN'
    },
    {
      value: 'TRANSPARENCY',
      ViewValue: 'TRANSPARENCY'
    },
    {
      value: 'BASE STANDING UNEVEN',
      ViewValue: 'BASE STANDING UNEVEN'
    },
    {
      value: 'BASE STANDING MATERIAL',
      ViewValue: 'BASE STANDING MATERIAL'
    },
    {
      value: 'OVER WEIGHT',
      ViewValue: 'OVER WEIGHT'
    },
    {
      value: 'LESS WEIGHT',
      ViewValue: 'LESS WEIGHT'
    },
    {
      value: 'FITTING',
      ViewValue: 'FITTING'
    },
    {
      value: 'FLASHING',
      ViewValue: 'FLASHING'
    },
    {
      value: 'SHORT MOULD',
      ViewValue: 'SHORT MOULD'
    },
    {
      value: 'CRACKING',
      ViewValue: 'CRACKING'
    },
    {
      value: 'BUBBLE',
      ViewValue: 'BUBBLE'
    },
    {
      value: 'SILVER STREAK',
      ViewValue: 'SILVER STREAK'
    },
    {
      value: 'H.O.N UNEVEN',
      ViewValue: 'H.O.N UNEVEN'
    },
    {
      value: 'COLOUR UNEVEN',
      ViewValue: 'COLOUR UNEVEN'
    },
    {
      value: 'WHITE MARK',
      ViewValue: 'WHITE MARK'
    },
    {
      value: 'SINK MARK',
      ViewValue: 'SINK MARK'
    },
    {
      value: 'RUNNER LINE',
      ViewValue: 'RUNNER LINE'
    },
    {
      value: 'OIL',
      ViewValue: 'OIL'
    },
    {
      value: 'BURN MARK',
      ViewValue: 'BURN MARK'
    },
    {
      value: 'BLOW UNCOMPLETE',
      ViewValue: 'BLOW UNCOMPLETE'
    },
    {
      value: 'THREAD DAMAGE',
      ViewValue: 'THREAD DAMAGE'
    },
    {
      value: 'WELD LINE',
      ViewValue: 'WELD LINE'
    },
    {
      value: 'WARPING',
      ViewValue: 'WARPING'
    },
    {
      value: 'PIN OF CENTRE',
      ViewValue: 'PIN OF CENTRE'
    },
    {
      value: 'BODY LINE',
      ViewValue: 'BODY LINE'
    },
    {
      value: 'INJ POINT HIGHT',
      ViewValue: 'INJ POINT HIGHT'
    },
    {
      value: 'NECK SLANTING',
      ViewValue: 'NECK SLANTING'
    },
    {
      value: 'POOR SURFACE GLOSS',
      ViewValue: 'POOR SURFACE GLOSS'
    },
    {
      value: 'SCRATCH',
      ViewValue: 'SCRATCH'
    },
    {
      value: 'DENTED',
      ViewValue: 'DENTED'
    },
    {
      value: 'HOLE',
      ViewValue: 'HOLE'
    },
    {
      value: 'LEAKAGE',
      ViewValue: 'LEAKAGE'
    },
    {
      value: 'OTHER',
      ViewValue: 'OTHER'
    }
  ];

  code: any;
  elem;
  email: any;

  constructor(
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private zone: NgZone,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private dateFormat: DateFormatService,
    private firestore: AngularFirestore,
    @Inject(DOCUMENT) private document: any
  ) {


    spinner.show();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.getFlaskServerStatus();
    this.flaskServertimerInterval = setInterval(() => {
      //this.getMachineFailureStatus();
      this.getFlaskServerStatus();
    }, 5000)
    this.DatetimerInterval = setInterval(() => {
      this.now = Date.now();
    }, 1000)

    this.subscribe = this.angularFireAuth.authState.subscribe(async authState => {

      if (authState === null) {
        this.subscribe.unsubscribe();
      } else {
        this.subscribe.unsubscribe();
        const snapshot = await this.db.database.ref('Operators').child(authState.uid).once('value');

        if (snapshot.exists()) {
          this.Machine_No = snapshot.child('machineAssigned').val();
          this.db.database.ref('Machine').child(this.Machine_No).on('value', async (DataSnapshot) => {
            this.getMachineInfo(DataSnapshot);
          })
        }

        this.partdb_Controller.getPartList().then(data => {
          data.forEach(async (element, index) => {
            const maclist = element.Available_Machine.split(',');

            maclist.forEach(machineno => {
              if(this.Machine_No === 'Machine ' + machineno){
                this.matSelectPart.push(element);
              }
            });
          });
        });
      }
    });
  }

  choosePart() {
    if(this.selectedPart!=null && this.selectedPart!=''){
      this.matSelectPart.forEach(async (element, index) => {
        if(this.selectedPart === element.Part_No){
          $('#partName').text(element.Part_Name);
        }
      });
    }
    else{
      $('#partName').text("-");
    }
  }

  show() {
    this.ViewUpcoming = !this.ViewUpcoming;
  }

  poChange(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(PoDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = 'auto';

        const position = {top: '5%'};
        dialogConfig.position = position;
        dialogConfig.disableClose = true;

        if(result.includes('/') || result.includes('@')){
          dialogConfig.data = '/ and @ is not allowed !!!';

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              $('#poNumber').text("-");
            }
          });
        }
        else{
          this.db.database.ref('Purchase Order/' + result).once('value').then(data => {
            if (data.exists()) {
              dialogConfig.data = 'PO Number has already existed !!!';

              this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
                if (data) {
                  $('#poNumber').text("-");
                }
              });
            }
            else{
              dialogConfig.data = 'Confirm Your PO Number is ' + result;

              this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
                if (data) {
                  $('#poNumber').text(result);
                  this.poNumber = result;
                }
              });
            }
          });
        }
      }
    });
  }

  staffChange(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(StaffDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = 'auto';

        const position = {top: '5%'};
        dialogConfig.position = position;
        dialogConfig.disableClose = true;

        if(result.includes('/') || result.includes('@')){
          dialogConfig.data = '/ and @ is not allowed !!!';

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {

            }
          });
        }
        else{
          this.db.database.ref('User').once('value').then(data => {
            if (data.exists()) {
              var found = false;
              var month = new Date().getMonth()+1;
              var year = new Date().getFullYear();
              var day = new Date().getDate();
              var hour = new Date().getHours();
              var minutes = new Date().getMinutes();
              var seconds = new Date().getSeconds();
              var currentTimestamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;

              data.forEach(childSnapshot2 => {
                if(childSnapshot2.child("StaffNo").val() == result){
                  found = true;
                }
              });

              if(found){
                dialogConfig.data = 'Confirm Your Staff Id is ' + result;

                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
                  if (data) {
                    if(this.Machine.Schedule_Track[0] != null){
                      var info= new StaffInfo();
                      info.StaffId = result;
                      info.Timestamp = currentTimestamp;
                      this.staffIds.push(info);
                      this.db.database.ref('Purchase Order/' + this.Machine.Schedule_Track[0].Machine_Schedule_PO_No + '/StaffIds').set(this.staffIds);
                      this.staffMessage = "";
                      this.staffMessage = this.staffIds[this.staffIds.length-1].StaffId;
                    }
                    else{
                      var info= new StaffInfo();
                      info.StaffId = result;
                      info.Timestamp = currentTimestamp;

                      this.staffIds.push(info);
                      this.staffMessage = "";
                      this.staffMessage = this.staffIds[this.staffIds.length-1].StaffId;
                    }
                  }
                });
              }
              else{
                dialogConfig.data = 'Staff Id not found !!!';

                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
                  if (data) {

                  }
                });
              }
            }
          });
        }
      }
    });
  }

  quantityChange(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = false;
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';

          const position = {top: '5%'};
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.data = 'Production Quantity is set to ' + result;

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              $('#productionQuantity').text(result);
              this.productionQuantity = result;
            }
          })
        }
        else {

        }
      }
    });
  }

  currentPO = '';
  getMachineInfo(DataSnapshot) {
    this.zone.run(async () => {
      this.Machine = this.machinedb_Controller.getSpecifyMachine(DataSnapshot);
      this.Machine.Schedule_Track = await this.getSortedScheduleList(this.Machine.Schedule_Track);

      if (this.Machine.Schedule_Track.length > 0) {
        if (this.Machine.Schedule_Track[0].DatePresettingStart == "-" &&
          this.Machine.Schedule_Track[0].DatePresettingEnd == "-" &&
          this.Machine.Schedule_Track[0].Machine_Schedule_Status == "Waiting") {
          this.Presetting_flag = true;
        }

        // Retrieve QC records
        this.weighting_Controller.getSpecificWeight(this.Machine.Schedule_Track[0].Machine_Schedule_PO_No, this.Machine.Schedule_Track[0].Machine_Schedule_Part_No).then(data => {
          if(data && data.PO_No){
            this.rejectMessage = "";
            this.totalReject = 0;

            data.Weight_Part_List[0].RejectedInfos.forEach(async (element, index) => {
              this.rejectedReasons.push(element.Code);
              this.rejectedTallies.push(element.Quantity);
              this.totalReject = parseInt(this.totalReject.toString()) + parseInt(element.Quantity.toString());
              this.rejectMessage += element.Code + " : " + element.Quantity + '\n';
            });
          }
        });

        if(this.currentPO != this.Machine.Schedule_Track[0].Machine_Schedule_PO_No){
          // Retrieve PO Id
          this.PODB_controller.search_PO(this.Machine.Schedule_Track[0].Machine_Schedule_PO_No).then(data => {
            this.currentPO = this.Machine.Schedule_Track[0].Machine_Schedule_PO_No
            if (data.PO_Staff_List.length > 0) {
              this.staffIds = data.PO_Staff_List;
              this.staffMessage = "";
              this.staffMessage = this.staffIds[this.staffIds.length - 1].StaffId;
            }
          });
        }
       
      }
      else{
        this.Presetting_flag = true;
        this.Machine.Schedule_Track.push(null);
      }

      let Stopped_Schedule = this.getStoppedSchedule(this.Machine.Schedule_Track);

      if (Stopped_Schedule != null && this.Machine.Machine_Code.match('-')) {
        //this.Display_flag = true;
        this.getFlaskServerStatus();
        for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
          this.Machine.Schedule_Track[i].Active_Schedule_Status = false;
        }
      }
    });

    //this.machinedb_Controller.getUnsolveMachineStatus(this.Machine.Machine_No).then(flag =>{})
  }

  getFlaskServerStatus() {
    this.httpClient.post('http://127.0.0.1:5002/handshaking', { responseType: 'text' }).subscribe(
      response => {
        var splitted_response = response.toString().split("@@@");
        //False = machine without failure
        //True = machine with failure
        var machineFailureStatus = (splitted_response[1] == "true")

        if (this.Machine) {
          let Stopped_Schedule = this.getStoppedSchedule(this.Machine.Schedule_Track)
          let InProgress_Schedule = this.getInProgressSchedule(this.Machine.Schedule_Track)

          if (splitted_response[0].match("Thread is stopped") || splitted_response[0].match("Thread is not exist")) {
            if (InProgress_Schedule != null) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.Machine.MachineStatus,
                code: this.Machine.Machine_Code,
                po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
                scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
                action: "Update Schedule Status to Stop due to thread is stopped or not exist"
              }

              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
              this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + InProgress_Schedule.Machine_Schedule_No).update({ '_Machine_Schedule_Status': 'Stopped' });
            }

            if (Stopped_Schedule != null) {
              if (this.Machine && this.Machine.Machine_Code.match('-')) {
                this.showToasterWarning();
                debugger;
                this.Display_flag = true;

                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              }
              else if (this.Machine && !machineFailureStatus) {
                this.Machine.Schedule_Track[0].Active_Schedule_Status = true;
                this.showToasterSuccess("The server is ready to start the task");
                this.Display_flag = false;
              }
              else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              }
            }
            else {
              let Waiting_Schedule = this.getWaitingSchedule(this.Machine.Schedule_Track)

              if (Waiting_Schedule != null && !machineFailureStatus) {
                this.Machine.Schedule_Track[0].Active_Schedule_Status = true;
              }
              else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                if (Waiting_Schedule != null) {
                  this.Machine.Schedule_Track[0].Active_Schedule_Status = false;
                }
              }
            }

          }
          else if (splitted_response[0].match("Thread is running")) {
            if (Stopped_Schedule != null) {
              if (this.Machine && this.Machine.Machine_Code.match('-')) {
                this.showToasterWarning();
                debugger;
                this.Display_flag = true;

                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              } else {
                this.Display_flag = false;
              }
            } else {
              this.Display_flag = false;
            }
          }
          this.zone.run(async () => { this.Machine, this.spinner.hide(); })
        }
      },
      error => {
        this.zone.run(async () => { this.spinner.hide(); })
        this.showToasterError("Ensure the flask server is connected");
        this.Display_flag = false;

        if (this.Machine) {
          let InProgress_Schedule = this.getInProgressSchedule(this.Machine.Schedule_Track)
          if (InProgress_Schedule != null) {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const log = {
              date: new Date(),
              status: this.Machine.MachineStatus,
              code: this.Machine.Machine_Code,
              po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
              scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
              action: "Update Schedule Status to Stop due to flask server is not connected"
            }
            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);

            this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + InProgress_Schedule.Machine_Schedule_No).update({ '_Machine_Schedule_Status': 'Stopped' });
          }

          for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
            this.Machine.Schedule_Track[i].Active_Schedule_Status = false
          }
        }
      }
    );
  }

  showToasterSuccess(message: string) {
    this.toast.success(message, "", { timeOut: 2000, positionClass: 'toast-top-left' });
  }

  showToasterError(message: string) {
    this.toast.toastrConfig
    this.toast.error(message, "Error!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showRestartToasterWarning(message: string) {
    this.toast.toastrConfig
    this.toast.warning(message, "Warning!", { timeOut: 15000, positionClass: 'toast-top-center' });
  }

  showToasterWarning() {
    this.toast.warning("Enter the interrupt code for the machine stopped", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showToasterMachineWarning() {
    this.toast.warning("The machine failure is not resolved.", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  getMachineFailureStatus() {
    this.httpClient.post('http://127.0.0.1:5002/machineFailureStatus', { responseType: 'text' }).subscribe(
      response => {
        if (response.toString().match("true")) {
          this.machineFailureStatus = true
        } else {
          this.machineFailureStatus = false
        }
      },
      error => {
        this.machineFailureStatus = true
      }
    )
  }

  setRejectTally(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {
          this.Reason_flag = true;
          this.rejectTally = result;
        }
        else {

        }
      }
    });
  }

  submitRejectCode(code, Schedule: ScheduleTracker) {
    var totalRejected = 0;
    var totalProduction = Schedule.Exp_Qty;
    this.rejectMessage = "";

    if(this.rejectedReasons.includes(code)){
      var temp = this.rejectedTallies[this.rejectedReasons.indexOf(code)];
      var newValue = parseInt(temp.toString()) + parseInt(this.rejectTally.toString());
      this.rejectedTallies[this.rejectedReasons.indexOf(code)] = newValue;
    }
    else{
      this.rejectedReasons.push(code);
      this.rejectedTallies.push(this.rejectTally);
    }

    let rejected= {};
    for(var i=0; i<this.rejectedReasons.length; i++){
      rejected[this.rejectedReasons[i]] = this.rejectedTallies[i];
      totalRejected += parseInt(this.rejectedTallies[i].toString());
      this.rejectMessage += this.rejectedReasons[i] + " : " + this.rejectedTallies[i] + '\n';
    }

    this.totalReject = totalRejected;

    this.weighting_Controller.getSpecificWeight(Schedule.Machine_Schedule_PO_No, Schedule.Machine_Schedule_Part_No).then(data => {
      if(data && data.PO_No){
        let newAccepted= {};
        var newAccept = totalProduction - totalRejected;
        newAccepted['Accepted Qty'] = newAccept;
        this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No).update(newAccepted);

        if(data.Weight_Part_List[0].RejectedInfos){
          this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No + '/Rejected Part').update(rejected);
        }
        else{
          this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No + '/Rejected Part').set(rejected);
        }
      }
      else{
        let qcRecord= {};
        qcRecord['QC Date'] = new Date().toISOString();
        qcRecord['Update_by'] = this.email;
        qcRecord['Accepted Qty'] = totalProduction - totalRejected;
        qcRecord['Rejected Part'] = rejected;
        this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No).set(qcRecord);
      }
    });

    this.Reason_flag = false;
  }

  setCounterTally() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {
          const dialogConfig = new MatDialogConfig();

          dialogConfig.autoFocus = false;
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';
          const position = {
            top: '5%'
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.data = 'Align the actual quantity to ' + result + '.\nThe changes will be reflected in the next production cycle.';
          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.Machine.MachineStatus,
                code: this.Machine.Machine_Code,
                po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
                scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
                action: "Tally Quantity"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
              this.httpClient.post('http://127.0.0.1:5002/tally', (result), { responseType: 'text' }).subscribe(
                data => { this.showToasterSuccess("The value will be updated on the next production cycle") },
                error => { }
              )
            }
          })
        }
        else {

        }
      }
    });
  }

  enterPinToFinishSchedule(machineNumber, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(PinDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        if (Number(result)) {
          var snapshot = await this.db.database.ref('Passcode').once('value');
          var isValidPin = false;
          if (snapshot.exists()) {
            snapshot.forEach(childsnapshot=>{
              if(childsnapshot.child("Passcode").val()){
                if (result == childsnapshot.child("Passcode").val()){
                  this.personInCharge = childsnapshot.child('PIC').val();
                  isValidPin = true;
                }
              }
            })

            if (isValidPin) {
              this.showToasterSuccess("Identity validated! Proceed to further action!");
              this.ScheduleFinishDialog(machineNumber, Schedule);
            } else {
              this.showToasterError("Invalid PIN number");
            }
          }
        } else {

        }
      }
    })
  }

  ScheduleFinishDialog(machineNumber, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    if (Schedule.Acc_Qty < Schedule.Exp_Qty) {
      dialogConfig.data = 'The production quantity (' + Schedule.Acc_Qty + ') is less than the PO required quantity!';
    }
    else {
      dialogConfig.data = 'The production quantity is ' + Schedule.Acc_Qty;
    }

    this.dialog.open(FinishDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.partdb_Controller.search_Part(Schedule.Machine_Schedule_Part_No).then(data => {
          Schedule.PartCavityNum = String(data.Number_Cavity);
          Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim();
          Schedule.PredefinedCycleTime = String(data.Cycle_Time);

          let startDate = new Date(Schedule.DateFormatStart);
          let endDate = new Date();
          //let presetStartDate = new Date(Schedule.DatePresettingStart);
          //let presetEndDate = new Date(Schedule.DatePresettingEnd);
          let totalRunTime = Math.abs(endDate.getTime() - startDate.getTime()) / 3600000;
          //let totalPresetTime = (Math.abs(presetEndDate.getTime() - presetStartDate.getTime())) / 3600000;
          let totalBreakTime = Schedule.TotalDownTime;

          Schedule.Availability = (totalRunTime - totalBreakTime )/totalRunTime;
          Schedule.Performance = (Schedule.Acc_Qty * (data.Cycle_Time / 3600)) / totalRunTime;

          const addPOModel = {
            po: Schedule.Machine_Schedule_PO_No,
            machineChosen: machineNumber,
            scheduleID: Schedule.Machine_Schedule_No,
            availability: Number.isFinite(Schedule.Availability) ? Schedule.Availability : 0,
            performance: Number.isFinite(Schedule.Performance) ? Schedule.Performance : 0,
            Acc_Qty: Schedule.Acc_Qty,
            pic: this.personInCharge,
          };

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());

          const info = {
            message: addPOModel.machineChosen + ' Job End by <b>' + this.email + '</b>\n' + Schedule.Machine_Schedule_PO_No,
            date: new Date(),
            addPOModel
          };

          this.rejectMessage = "";
          this.totalReject = 0;
          this.staffIds = [];
          this.staffMessage = "";
          this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

          this.httpClient.post('http://127.0.0.1:5002/endSchedule', (Schedule), { responseType: 'text' }).subscribe(
            response => {
              this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF"});
              this.db.database.ref('Machine/' + this.Machine_No).update({ "Code": "-"});
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: 'OFF',
                code: this.Machine.Machine_Code,
                po: Schedule.Machine_Schedule_PO_No,
                scheduleNo: Schedule.Machine_Schedule_No,
                action: "End Schedule"
              }

              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);

              if (response.toString().match("Production completed")) {
                this.showToasterSuccess("Scheduled task completed!");
                this.machinedb_Controller.updateJobOEE(addPOModel);
              }
            },
            error => {
              this.showToasterError("The scheduled task unable to finish due to the flask server is not connected.");
            }
          );
        })
      }
    })
  }

  ngOnInit() {
    this.elem = document.getElementById('full');
  }

  ngAfterViewInit(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }

  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    clearInterval(this.DatetimerInterval);
    clearInterval(this.flaskServertimerInterval);
  }

  restartWebApp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Close and restart the current web application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.httpClient.post('http://127.0.0.1:5002/RestartWebApp', { responseType: 'text' }).subscribe(
          data => {
            this.showToasterSuccess("Restarting the web application!")
          },
          error => {
            this.showRestartToasterWarning("Unable to locate the flask server, please the restart server manually! \n **(Close the web app -> click the ClickMe.sh)**");
          }
        );
      }
    })
  }

  startProcess(Schedule: ScheduleTracker) {
    this.Button_flag = true;

    if(Schedule == null){
      if (!this.poNumber) {
        this.toast.error('Missing PO name ', 'Please fill in!');
        return;
      }

      if (!this.productionQuantity) {
        this.toast.error('Missing Production Quantity ', 'Please fill in!');
        return;
      }

      if (!this.selectedPart) {
        this.toast.error('Missing Part No ', 'Please Select!');
        return;
      }

      if (this.staffIds.length <= 0) {
        this.toast.error('Missing Staff Id ', 'Please fill in!');
        return;
      }

      let partNo : any = this.selectedPart;

      this.partdb_Controller.search_Part(partNo).then(async data => {
        Schedule = new ScheduleTracker;
        Schedule.Button_Status = true;
        Schedule.Active_Schedule_Status = false;
        this.Schedule_Part_No = partNo;

        let scheduleNo = uuidv4();
        Schedule.Machine_Schedule_PO_No = this.poNumber;
        Schedule.Machine_Schedule_Part_No = partNo;
        Schedule.PartCavityNum = String(data.Number_Cavity);
        Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim();
        Schedule.PredefinedCycleTime = String(data.Cycle_Time);
        Schedule.HourLeft = (data.Cycle_Time * (this.productionQuantity / data.Number_Cavity)) / 3600;
        Schedule.Machine_Progress = 0;
        Schedule.Acc_Qty = 0;
        Schedule.Availability = 0;
        Schedule.Machine_Schedule_Code = "-";
        Schedule.Effectiveness = 0;
        Schedule.Performance = 0;
        Schedule.TotalDownTime = 0;
        Schedule.DateDown_Start = 0;
        Schedule.DateDown_End = 0;
        Schedule.Exp_Qty = this.productionQuantity;
        Schedule.POQuantity = this.productionQuantity;
        Schedule.Machine_Schedule_Status = "In Progress";
        Schedule.Machine_Schedule_Start_Date = new Date().toISOString();
        Schedule.Machine_Schedule_End_Date = new Date(new Date().getTime() + Schedule.HourLeft * 60 * 60000).toISOString();

        if (Schedule.Acc_Qty === 0) {
          await this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + scheduleNo).set(Schedule);
          Schedule.Machine_Schedule_No = scheduleNo;

          const addModel = {
            machineChosen: this.Machine_No,
            scheduleID: scheduleNo,
            startTimer: this.startTimer,
          };

          const addPOModel = {
            machineChosen: this.Machine_No,
            scheduleID: scheduleNo,
            endTimer: this.endTimer,
          };

          this.machinedb_Controller.updatePresetStartTime(addModel);
          this.machinedb_Controller.updatePresetEndTime(addPOModel);
        }

        // Insert PO
        let newPO = {};
        newPO['_Created_Date'] = new Date().toISOString();
        newPO['_Updated_Date'] = new Date().toISOString();
        newPO['_Created_By'] = this.email;
        newPO['_Updated_By'] = this.email;

        const info = {};
        info['Part No'] = partNo;
        info['Part Name'] = data.Part_Name;
        info['Part Quantity'] = this.productionQuantity;
        info['PO Quantity'] = this.productionQuantity;
        info['Status'] = "In Progress";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['MachineNo'] = this.Machine_No;
        newPO['Part List'] = info;
        newPO['StaffIds'] = this.staffIds;

        this.db.database.ref('Purchase Order/' + this.poNumber).set(newPO);

        let qcRecord= {};
        qcRecord['QC Date'] = new Date().toISOString();
        qcRecord['Update_by'] = this.email;
        qcRecord['Accepted Qty'] = this.productionQuantity;

        await this.db.database.ref('QC/' + Schedule.Machine_Schedule_PO_No + '/' + Schedule.Machine_Schedule_Part_No).set(qcRecord);

        this.httpClient.post('http://127.0.0.1:5002/', (Schedule), { responseType: 'text' }).subscribe(
          data => {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());

            const info = {
              message: "Machine " + this.Machine_No + ' started by <b>' + this.email + '</b>\n',
              date: new Date(),
            };

            const status = {
              Status: "In Progress",
            }

            this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No).update(status);
            this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
            this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);
            this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "ON" });

            const log = {
              date: new Date(),
              status: 'ON',
              code: this.Machine.Machine_Code,
              po: Schedule.Machine_Schedule_PO_No,
              scheduleNo: Schedule.Machine_Schedule_No,
              action: "Start Process"
            }

            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
            this.machinedb_Controller.Machine_restoreLogState(this.Machine_No, Schedule.Machine_Schedule_No)
            this.Button_flag = false;
          },
          error => {
            this.Button_flag = false;
          }
        )
      });
    }
    else{
      Schedule.Button_Status = true;
      Schedule.Active_Schedule_Status = false;

      this.partdb_Controller.search_Part(Schedule.Machine_Schedule_Part_No).then(data => {
        Schedule.PartCavityNum = String(data.Number_Cavity);
        Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim();
        Schedule.PredefinedCycleTime = String(data.Cycle_Time);

        if (Schedule.Acc_Qty === 0) {
          const d = {
            startAt: new Date(),
            endAt: new Date(new Date().getTime() + (Schedule.DateFormatEnd.getTime() - Schedule.DateFormatStart.getTime()))
          }

          this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + Schedule.Machine_Schedule_No).update(d);
        }

        this.httpClient.post('http://127.0.0.1:5002/', (Schedule), { responseType: 'text' }).subscribe(
          data => {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());

            const info = {
              message: "Machine " + this.Machine_No + ' started by <b>' + this.email + '</b>\n',
              date: new Date(),
            };

            const status = {
              Status: "In Progress",
            }

            this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No).update(status);
            this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
            this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);
            this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "ON" });

            const log = {
              date: new Date(),
              status: 'ON',
              code: this.Machine.Machine_Code,
              po: Schedule.Machine_Schedule_PO_No,
              scheduleNo: Schedule.Machine_Schedule_No,
              action: "Start Process"
            }

            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
            this.machinedb_Controller.Machine_restoreLogState(this.Machine_No, Schedule.Machine_Schedule_No)
            this.Button_flag = false;
          },
          error => {
            this.Button_flag = false;
          }
        )
      });
    }
  }

  async getPart(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(schedule.Machine_Schedule_Part_No).once('value');
    if (snapshot.exists()) {
      this.mould = snapshot.child('Mould/Mould Referance No').val();

      if (this.photo !== snapshot.child('PhotoURL').val()) {
        this.photo = snapshot.child('PhotoURL').val();
      }
    }
  }

  async getRawMaterialUsed(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').
      child(schedule.Machine_Schedule_PO_No + '/Part List/' + schedule.Machine_Schedule_No + '/Raw Materials Used').once('value');
    if (snapshot.exists()) {
      schedule.RawMaterialUsed = [];
      snapshot.forEach(element => {
        element.forEach(s => {
          console.log(s.val());
          const index = schedule.RawMaterialUsed.findIndex(u => u.MaterialName === s.child('RawMaterialName').val());
          if (index !== -1) {
            schedule.RawMaterialUsed[index].Quantity += parseInt(s.child('Quantity Checkout').val());
          } else {
            const rawMaterialUsed = new RawMaterialUsed;
            rawMaterialUsed.Quantity = s.child('Quantity Checkout').val();
            rawMaterialUsed.MaterialName = s.child('RawMaterialName').val();
            schedule.RawMaterialUsed.push(rawMaterialUsed);
          }
        })
      })
    }
  }

  async getSortedScheduleList(Schdule_TrackList: ScheduleTracker[]): Promise<ScheduleTracker[]> {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }

    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList);

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList);

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false;
      SortedScheduleList.unshift(InProgress_Schedule);
    }
    else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true;
      SortedScheduleList.unshift(Stopped_Schedule);
    }
    else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true;
    }

    if (SortedScheduleList.length > 0) {
      await this.getPart(SortedScheduleList[0]);
      await this.getRawMaterialUsed(SortedScheduleList[0]);
    }

    return SortedScheduleList;
  }

  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }

      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let InProgress_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {
        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }

    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  submitCode(code, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Submit this interrupt code (' + code + ')?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.getFlaskServerStatus();
        this.db.database.ref('Machine/' + this.Machine_No).update({ Code: code });
        this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF" });

        const status = {
          Status: "Stopped",
        }
        this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No).update(status);
        if (Schedule.Machine_Schedule_No2)
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No2).update(status);
        if (Schedule.Machine_Schedule_No3)
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No3).update(status);

        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: "Machine " + this.Machine_No + ' Submit code by <b>' + this.email + '</b>\n',
          date: new Date(),
        };

        this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

        const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
        const log = {
          date: new Date(),
          status: 'OFF',
          code: code,
          action: "Submit Interrupt Code",
          po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
          scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No
        }
        this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
        this.machinedb_Controller.setMachine_Logfile(this.Machine_No, this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No, code);
        this.CodeSubmitted_flag = true;
        // document.getElementById("overlay").style.display = "none";
      }
    })
  }

  stop() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };

    this.AlertTimerFunc();
  }

  AlertTimerFunc() {
    let counter: number = 900 //seconds
    this.AlertTimer = setInterval(() => {
      if (counter > 0) {
        counter--;
        let m: number = Math.floor(counter / 60);
        let s: number = counter % 60;

        let minutes = m < 10 ? "0" + m : String(m);
        let seconds = s < 10 ? "0" + s : String(s);

        this.AlertCountDown = minutes + ":" + seconds;
      } else {
        clearInterval(this.AlertTimer);
      }
    }, 1000)
  }

  viewScheduleDetail(schedule): void {
    const dialogRefupdatePart = this.dialog.open(DetailsScheduleComponent, {
      data: schedule,
      width: '70%',
      height: '30%'
    });
  }

  getFilterCode(color){
    return this.InterruptCodeList.filter(e=>e.Color === color);
  }

  startPresettingTime() {
    let startTime = Date.now();
    this.startTimer = startTime;
    let element = <HTMLInputElement>document.getElementById("mouldSetEnd");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldSetStart");
    element1.disabled = true;
  }

  continueProcess(message: string, message1: string) {
    let element = <HTMLInputElement>document.getElementById(message);
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById(message1);
    element1.disabled = true;
  }

  endPresettingTime() {
    let endTime = Date.now();
    this.endTimer = endTime;
    let element = <HTMLInputElement>document.getElementById("mouldTestEnd");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldTestStart");
    element1.disabled = true;
    this.Presetting_flag = false;
    this.resetPresetGUI();
  }

  resetPresetGUI() {
    let element = <HTMLInputElement>document.getElementById("mouldSetStart");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldSetEnd");
    element1.disabled = true;
    let element2 = <HTMLInputElement>document.getElementById("preheatStart");
    element2.disabled = true;
    let element3 = <HTMLInputElement>document.getElementById("preheatEnd");
    element3.disabled = true;
    let element4 = <HTMLInputElement>document.getElementById("mouldTestStart");
    element4.disabled = true;
    let element5 = <HTMLInputElement>document.getElementById("mouldTestEnd");
    element5.disabled = true;
  }
}

export class SchedulerComponent {
  private Machine_Schedule_No: string;
  private Exp_Qty: number;
  private Acc_Qty: number;
  private Machine_Schedule_PO_No: string;
  private Machine_Schedule_Part_No: string;
  private Machine_Schedule_Status: string;

  constructor() { }
}
