import { OeeCalculationComponent } from './oee-calculation/oee-calculation.component';
import { data } from 'jquery';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { InvoiceDB_controller } from '../../../Services/DB_Controller/InvoiceDB_controller';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AngularFireStorage } from '@angular/fire/storage';
import { PackingListDB_controller } from '../../../Services/DB_Controller/PackingListDB_controller';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { StringValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { isNullOrUndefined } from 'util';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-oee-history',
  templateUrl: './oee-history.component.html',
  styleUrls: ['./oee-history.component.css']
})
export class OEEHistoryComponent {

  machine: Machine;
  machineNum: string;
  monthOEE: number = 0;
  totalNoJobHours: number = 0;
  totalPresetTime: number = 0;
  totalDowntime: number = 0;
  totalRunTime: number = 0;

  searchYear: string;
  searchMonth: string;
  matSelectYear: string[] = [];
  cloneYear: string[] = [];
  matSelectMonth: string[] = [];
  cloneMonth: string[] = [];
  selectedMonthJobList: ScheduleTracker[] = [];
  oeeYear: string;
  oeeMonth: string;

  selectYear: any[] = [{
    value: new Date().getFullYear(),
    label: new Date().getFullYear()
  }, {
    value: new Date().getFullYear() - 1,
    label: new Date().getFullYear() - 1
  }, {
    value: new Date().getFullYear() - 2,
    label: new Date().getFullYear() - 2
  },
  {
    value: new Date().getFullYear() - 3,
    label: new Date().getFullYear() - 3
  },
  {
    value: new Date().getFullYear() - 4,
    label: new Date().getFullYear() - 4
  }]
  selectMonth: any[] = [{
    value: 1,
    label: 'January'
  }, {
    value: 2,
    label: 'February'
  }, {
    value: 3,
    label: 'March'
  }, {
    value: 4,
    label: 'April'
  }, {
    value: 5,
    label: 'May'
  }, {
    value: 6,
    label: 'June'
  }, {
    value: 7,
    label: 'July'
  }, {
    value: 8,
    label: 'August'
  }, {
    value: 9,
    label: 'September'
  }, {
    value: 10,
    label: 'October'
  }, {
    value: 11,
    label: 'November'
  }, {
    value: 12,
    label: 'December'
  }]

  allSchedule: ScheduleTracker[] = [];
  constructor(
    private dialogRef: MatDialogRef<OEEHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,

  ) {
    this.allSchedule = data.allSchedule;
    this.machineNum = data.machineNumber;
  }

  OEEScheduleList: ScheduleTracker[] = [];
  nonOEEScheduleList = [];
  AverageP: number = 0;
  AverageA: number = 0;
  AverageQ: number = 0;

  change() {
    this.spinner.show()
    this.AverageP = 0
    this.AverageA = 0
    this.AverageQ = 0
    this.monthOEE = 0
    this.totalDowntime = 0
    this.totalNoJobHours = 0
    this.totalPresetTime = 0
    this.totalRunTime = 0
    if (this.oeeYear && this.oeeMonth) {
      this.OEEScheduleList = this.allSchedule.filter(e =>
        e.DateFormatStart.getFullYear() === parseFloat(this.oeeYear) &&
        e.DateFormatStart.getMonth() === parseFloat(this.oeeMonth) - 1 &&
        e.OEEDone)
      this.nonOEEScheduleList = this.allSchedule.filter(e =>
        e.DateFormatStart.getFullYear() === parseFloat(this.oeeYear) &&
        e.DateFormatStart.getMonth() === parseFloat(this.oeeMonth) - 1 &&
        !e.OEEDone)
      this.AverageP = this.OEEScheduleList.map(a => a.Performance).reduce((a, b) => { return a + b }, 0) / this.OEEScheduleList.length || 0;
      this.AverageA = this.OEEScheduleList.map(a => a.Availability).reduce((a, b) => { return a + b }, 0) / this.OEEScheduleList.length || 0;
      this.AverageQ = this.OEEScheduleList.map(a => a.Effectiveness).reduce((a, b) => { return a + b }, 0) / this.OEEScheduleList.length || 0;
      this.monthOEE = (this.AverageP * this.AverageQ * this.AverageA) * 100;
      this.AverageA = this.AverageA * 100;
      this.AverageP = this.AverageP * 100;
      this.AverageQ = this.AverageQ * 100;

      for (const machine of this.OEEScheduleList) {
        let runTime = (machine.DateFormatEnd.getTime() - machine.DateFormatStart.getTime()) / 3600000
        let potentialQty = runTime / (machine.CycleTime / 3600)
        machine.OEE1 = (machine.QCQuantity / potentialQty)
      }
      this.totalNoJobHours = this.OEEScheduleList.map(a => a.DateFormatEnd.getTime() - a.DateFormatStart.getTime()).reduce((a, b) => { return a + b }, 0)
      this.totalPresetTime = this.OEEScheduleList.map(a => parseFloat(a.DatePresettingEnd) - parseFloat(a.DatePresettingStart)).reduce((a, b) => { return a + b }, 0)
      this.totalDowntime = this.OEEScheduleList.map(a => a.TotalDownTime).reduce((a, b) => { return a + b }, 0)
      this.totalNoJobHours = this.totalNoJobHours / 3600000;
      this.totalPresetTime = this.totalPresetTime / 3600000;
      this.totalRunTime = this.totalNoJobHours - this.totalPresetTime - this.totalDowntime;
    }
    this.spinner.hide()
  }

  findYear(cus) {
    if (!this.cloneYear) { return; }
    const search = cus.value;
    this.matSelectYear = this.cloneYear.filter(p => p.includes(search));
  }

  findMonth(cus) {
    if (!this.cloneMonth) { return; }
    const search = cus.value;
    this.matSelectMonth = this.cloneMonth.filter(p => p.includes(search));
  }

  cancel() {
    this.dialogRef.close(false);
  }
  Nan(value) {
    if (isNaN(value) || isNullOrUndefined(value))
      return 0
    else
      return value
  }

  CalculateOEE(job) {
    if (job.Availability && job.Performance && job.Effectiveness) {
      return job.Availability * job.Performance * job.Effectiveness
    } else {
      return 0;
    }
  }

  view(machine) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = machine
    this.dialog.open(OeeCalculationComponent, dialogConfig);
  }
}
