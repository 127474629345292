import { PartTracker } from './../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-detail-po-page',
  templateUrl: './detail-po-page.component.html',
  styleUrls: ['./detail-po-page.component.css']
})
export class DetailPOPageComponent implements OnInit {

  po: PurchaseOrder = new PurchaseOrder();
  PODB_controller: PODB_controller = new PODB_controller(this.db);
  rawTypes = [];
  email:string;
  constructor(
    public dialogRef: MatDialogRef<DetailPOPageComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,

    @Inject(MAT_DIALOG_DATA) public data) {
      this.angularFireAuth.authState.subscribe(auth => {
        this.email = auth.email;
      });
      this.po =cloneDeep(data);
    }
  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
  update(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update this with this POS? ' + this.po.PO_No;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {

        for (const part of this.po.PO_Part_List) {
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+part.ID + '/POS').set(part.POS);
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+part.ID + '/Reference').set(part.Reference);
          for(const p of part.subPart){
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+p.ID + '/POS').set(p.POS);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+p.ID + '/Reference').set(p.Reference);
          }
        }

        const update ={}
        update[this.po.PO_No + '/_Updated_Date'] = new Date();
        update[this.po.PO_No + '/_Updated_By'] = this.email;
        this.db.database.ref('Purchase Order/').update(update);


        this.dialogRef.close(this.po);
      }
    });
  }


  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteSchedule(part:PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this schedule?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.PODB_controller.delete_ScheduleFromPO(this.po.PO_No, part.MachineNo, part.ID, part.subPart);
        this.dialogRef.close(true);
      }
    })
  }


}

