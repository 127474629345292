import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { OrderRawMaterialComponent } from '../../order-raw-material/order-raw-material.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-enter-receive-details',
  templateUrl: './enter-receive-details.component.html',
  styleUrls: ['./enter-receive-details.component.css']
})
export class EnterReceiveDetailsComponent implements OnInit {

  quantity: number;
  receivedDate = new Date();
  maxDate :Date;
  lotNumber: any;
  rack: any;
  rackNumber: any;
  invoice: any;
  packets: number = 0;
  rawdata: any;
  box: any;
  array: any;
  amount: any;
  disabledAll = false;
  disabledSome = false;
  details: number = 0;
  constructor(
    private dialogRef: MatDialogRef<OrderRawMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private storage: AngularFireStorage

  ) {
    if(data.status==='View'){
      this.disabledAll = true;
      this.rawdata = data.value;
      this.rawdata.Unit = data.data.Unit
      this.quantity = this.rawdata.Quantity_PerBags;
      this.amount = this.rawdata.Received_Quantity;
      this.invoice = this.rawdata.Invoice_Number;
      this.packets = this.rawdata.Packets;
      this.box = this.rawdata.Box_Number;
      this.rack = this.rawdata.Rack_Number.slice(0,2).replace('-','');
      this.rackNumber = this.rawdata.Rack_Number.slice(2,this.rawdata.Rack_Number.length).replace('-','');;
      this.lotNumber = this.rawdata.Lot_Number;
      this.receivedDate = this.rawdata.Received_Date;
    }else{
      this.rawdata = data.event;
      if(this.rawdata.PO_RawMaterial_Status === 'Still Pending'){
        this.disabledSome = true;
      }
      if(this.rawdata.ReceivedDetailsList){
        this.rawdata.ReceivedDetailsList.forEach(element => {
          this.details += parseFloat(element.Received_Quantity)
          this.invoice = element.Invoice_Number;
          this.box = element.Box_Number;
          this.rack = element.Rack_Number.slice(0,2).replace('-','');
          this.rackNumber = element.Rack_Number.slice(2,element.Rack_Number.length).replace('-','');;
          this.lotNumber = element.Lot_Number;
        });
      }
      if(this.rawdata.expectedQuantity < this.rawdata.quantity_perBag)
        this.quantity = parseFloat(this.rawdata.quantity_perBag);

      this.rawdata.expectedQuantity = parseFloat(this.rawdata.PO_RawMaterial_Qty);
      this.rawdata.ReceivedDetailsList.forEach(element => {
        this.rawdata.expectedQuantity = (parseFloat(this.rawdata.expectedQuantity) - parseFloat(element.Received_Quantity));
      });
      this.db.database.ref('Supplier').child(this.rawdata._Supplier).once('value').then(r=>{
        this.rawdata.SupplierName = r.child('_Supplier_Name').val();
      })
    }
    this.array = new Array(100);
    this.maxDate = new Date();
  }

  quantityChange(event,model:NgModel){
    if(this.quantity && this.packets){
      this.amount = this.quantity * this.packets;
      if(this.amount > this.rawdata.expectedQuantity){
        model.control.setErrors({invalid: true});
      }
    }
  }
  unitQuantityChange(event,model:NgModel){
    this.amount = this.quantity;
    if(this.amount > this.rawdata.expectedQuantity){
        model.control.setErrors({invalid: true});
    }
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }
  file: any;
  fb
  downloadURL: Observable<string>;
  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm this details?';
    if(!this.file){
      this.toast.error('Please insert the image','Warning');
      return;
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.details = this.details + parseFloat(this.amount);

        var n = Date.now();
        const filePath = `RawMaterialsReceivedImages/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`RawMaterialsReceivedImages/${n}`, this.file);
        task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(url => {
              if (url) {
                this.fb = url;
                const value = {
                  ID: this.rawdata.PO_RawMaterial_ID,
                  quantity: this.quantity.toString(),
                  receivedDate: this.receivedDate,
                  rack: this.rack + '-' + this.rackNumber,
                  packets: this.packets.toString(),
                  invoice: this.invoice,
                  lotNumber: this.lotNumber,
                  amount: this.amount,
                  receivedQuantity: this.details,
                  // box: this.box,
                  status: this.rawdata.expectedQuantity !== parseFloat(this.amount)? 'Still Pending': 'Completed',
                  uid: uuidv4(),
                  file:url
                }
                this.dialogRef.close(value);
              }
            });
          })
        )
        .subscribe(url => {
          if (url) {
          }
        });

      }
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
  }


}
