import { AngularFireStorage } from '@angular/fire/storage';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { BoxNumberComponent } from './box-number/box-number.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSelectionListChange, MAT_DIALOG_DATA } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { GenerateQRCodeComponent } from '../../raw/generate-qrcode/generate-qrcode.component';
import jsPDF from 'jspdf';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import 'jspdf-autotable';

@Component({
  selector: 'app-generate-qrcode-dialog',
  templateUrl: './generate-qrcode-dialog.component.html',
  styleUrls: ['./generate-qrcode-dialog.component.css']
})
export class GenerateQRCodeDialogComponent implements OnInit {
  POController: PODB_controller = new PODB_controller(this.db);
  PartDB_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);

  email: string;
  POlist: PurchaseOrder[];
  selectedPO: any = [];
  selectedPart = [[]];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrCodes = [];
  detailsPO = [];
  dateFrom: Date;
  dateTo: Date;
  maxDate: Date;
  ClonePO: PurchaseOrder[];
  constructor(
    private dialogRef: MatDialogRef<GenerateQRCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 1)
    this.dateFrom = new Date();
    this.dateFrom.setMonth(this.dateFrom.getMonth() - 1);
    this.dateTo = new Date();
    this.spinner.show()
  }

  applyFilter(value){
    this.update(false)
    this.POlist = this.POlist.filter(p=>p.PO_No.toLowerCase().includes(value.toLowerCase()));
  }

  ngOnInit() {
    this.POController.search_PO_withStatusCompleted().then(po => {
      this.POlist = po;
      this.ClonePO = this.POlist.slice();
      this.update();
      this.spinner.hide();
    });
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm(part, po) {
    this.qrCodes = [];
    if(po.POS)
      part.POS = po.POS;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Generate label?';
    var boxNeeded;
    var extraBoxNeeded;
    if (part.TakeFromStocks.length > 0) {
      boxNeeded = Math.ceil(part.PO_Part_Qty / parseInt(part.QuantityPerBox));

      var remaining = part.POQuantity - part.PO_Part_Qty;
      extraBoxNeeded = Math.ceil(remaining / parseInt(part.QuantityPerBox));
      var counter = 1;
      var qty = parseInt(part.PO_Part_Qty);
      var remain;

      for (let i = 1; i <= boxNeeded; i++) {

        remain = qty;
        qty -= parseInt(part.QuantityPerBox);
        if (qty > 0) {
          var qr = {
            id: counter,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo + '$' + (part.QuantityPerBox)
          }
          this.qrCodes.push(qr);
        } else {
          var qr = {
            id: counter,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo + '$' + (remain)
          }
          this.qrCodes.push(qr);
        }

        counter++;
      }

      var r = part.POQuantity - part.PO_Part_Qty;
      for (let i = 1; i <= extraBoxNeeded + 1; i++) {
        if (i === extraBoxNeeded + 1) {
          const qr = {
            id: counter,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo +'$' + (0)
          }
          this.qrCodes.push(qr);

        } else {
          remain = r;
          r -= parseInt(part.QuantityPerBox);
          if (r > 0) {
            var qr = {
              id: counter,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo + '$' + (part.QuantityPerBox)
            }
            this.qrCodes.push(qr);
          } else {
            var qr = {
              id: counter,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo + '$' + (remain)
            }
            this.qrCodes.push(qr);
          }
        }
        counter++;
      }

    } else {


      let POQuantity;

      if(parseFloat(part.POQuantity) !== 0){
        POQuantity = part.POQuantity / parseFloat(part.QuantityPerBox);
        boxNeeded = Math.ceil(part.POQuantity / parseInt(part.QuantityPerBox));
      }else{
        POQuantity = part.PO_Part_Qty / parseFloat(part.QuantityPerBox);
        boxNeeded = Math.ceil(part.PO_Part_Qty / parseInt(part.QuantityPerBox));
      }

      if(Math.ceil(POQuantity) === Math.floor(POQuantity-1)){
        extraBoxNeeded = part.CartonBoxNeeded - boxNeeded + 1;
      }else{
        extraBoxNeeded = boxNeeded;
      }

      var r = 0;
      var qty = parseInt(part.POQuantity);
      for (let i = 1; i <= boxNeeded + 1; i++) {
        if (i === boxNeeded+1) {
          const qr = {
            id: i,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo +'$' + (0)
          }
          this.qrCodes.push(qr);
          break;
        }else{
          r = qty;
          qty -= parseInt(part.QuantityPerBox);
          if (qty > 0) {
            var qr = {
              id: i,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo + '$' + (part.QuantityPerBox)
            }
            this.qrCodes.push(qr);
          } else {
            var qr = {
              id: i,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.PO_Part_No + '$' + part.PackingListNo + '$' + (r)
            }
            this.qrCodes.push(qr);
          }
        }
      }
    }


    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {

        this.spinner.show();
        try {
         this.generateLabel(part, boxNeeded, extraBoxNeeded, po.PO_No);
         this.spinner.hide();

        } catch (error) {
          this.toast.error(error)
          this.spinner.hide();

        }
        this.qrCodes = [];
      }
    });
  }


  change(event) {
    const length = this.selectedPO.length;
    this.selectedPart.length = length;
  }


  enterDetails(event: MatSelectionListChange) {
    if (event.option.selected) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = '60%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = event.option.value;
      this.dialog.open(BoxNumberComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          this.detailsPO.push(result);
        } else {
          event.option.selected = false;
        }
      });
    } else {
      this.removeDetails(event.option.value);
    }
  }
  removeDetails(part) {
    this.detailsPO = this.detailsPO.filter(d => d.ID !== part.PO_Part_No);
  }
  getBase64Image(img) {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0,0, 125,   125);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    } catch (error) {
      throw new Error("No Image for part");
    }

  }

  search ='';
  update(flag = true) {
    if(flag)
      this.search= '';
    this.POlist = this.ClonePO.filter(p => p.Created_Date.getTime() >= this.dateFrom.getTime()
    && p.Created_Date.getTime() <= this.dateTo.getTime())
  }


  generateLabel(info: any, boxNeeded, extraBoxNeeded, pono): Promise<void> {

    const doc = new jsPDF('portrait', 'mm', 'a5');
    if (info.TakeFromStocks.length > 0) {
      var remaining = info.PO_Part_Qty;
      var extraLabel = "";

      if(remaining === 0 ){
        remaining = info.POQuantity;
        extraLabel = "(Take From Stock)";
      }

      for (let index = 1; index <= (boxNeeded + extraBoxNeeded) + 1; index++) {
        const qrcode = document.getElementById(index.toString());
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);

        var r = remaining;
        remaining -= parseInt(info.QuantityPerBox);
        doc.setFontSize(12);
        doc.setLineWidth(0.2);
        doc.line(2, 2, 145, 2);    //horizontal line
        doc.line(2, 2, 2, 205);    //vertical line
        doc.line(145, 2, 145, 205);
        doc.line(70, 2, 70, 205);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(1) Receiver', 4, 7);
        doc.setFontType('unbold');
        doc.setFontSize(10);
        const text = info.CustomerName + "\n" + info.CustomerAddress;
        const splitTitle = doc.splitTextToSize(text, 50);
        doc.text(splitTitle, 4, 12);
        doc.setFontType('bold');
        doc.text('(2) Product Photo', 72, 7);
        const photo = document.createElement('img');
        photo.src = info.PartPhoto;
        doc.addImage(photo, 'PNG', 90, 7, 30, 30);
        doc.line(2, 39, 145, 39);
        doc.setFontType('bold');
        doc.text('(3) Invoice / Packing List No.', 4, 45);
        doc.setFontType('unbold');
        doc.setFontSize(12);
        if (info.PackingListNo &&  extraLabel !== '(Extra)')
          doc.text("PL NO: "+ info.PackingListNo, 6, 55);
      // if (info.InvoiceNo)
      //   doc.text(info.InvoiceNo, 6, 65);
      if(pono){
        const d = doc.splitTextToSize("PO: "+pono, 70);
        doc.text(d, 6, 62);
      }
      if(info.Reference){
        const d = doc.splitTextToSize(info.Reference, 70);
        doc.text(d, 14, 69);
      }
      if(info.RevNO){
        const d = doc.splitTextToSize("REV: "+info.RevNO, 70);
        doc.text(d, 6, 76);

      }
      if(info.POS){
        const d = doc.splitTextToSize("POS: "+info.POS, 70);
        doc.text(d, 6, 83);
      }


        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text('(4) Supplier Address', 72, 45);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.setFontType('Tunga');
        doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
        doc.text('NO 12 & 14 LORONG PERINDUSTRIAN', 72, 55);
        doc.text('BUKIT MINYAK 5,', 72, 60);
        doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 65);
        doc.text('14100 BUKIT MERTAJAM,', 72, 70);
        doc.text('PULAU PINANG, WEST MALAYSIA', 72, 75);

        doc.line(70, 80, 145, 80);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(5) Net WT', 72, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(info.Part_Weight + ' GM', 77, 88);
        doc.line(91, 80, 91, 90);
        var crossWeight = (parseFloat(info.Part_Weight) * parseFloat(info.QuantityPerBox)+ parseFloat(info.Carton_Weight))/1000

        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(6) Gross WT', 92, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(crossWeight.toFixed(2) + ' KG', 97, 88);
        doc.line(120, 80, 120, 90);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(7) NO Boxes', 122, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        if (index === (boxNeeded + extraBoxNeeded) + 1) {
          doc.text(1 + ' of ' + 1, 126, 88);
        } else {
          if (extraLabel !== '(Take From Stock)' && boxNeeded > 0)
            doc.text(index + ' of ' + boxNeeded, 126, 88);
          else {
            doc.text(index - boxNeeded + ' of ' + extraBoxNeeded, 126, 88);
          }
        }

        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.line(2, 90, 145, 90);
        doc.text('(8) QR Code', 4, 95);
        doc.addImage(imageData, 'JPG', 10, 97);
        doc.text('(9) Customer Part NO (P)', 72, 95);
        doc.setFontType('bold');
        doc.setFontSize(18);
        const splt = doc.splitTextToSize(info.PO_Part_No, 60);
        doc.text(splt, 75, 105);
        doc.line(2, 132, 145, 132);
        doc.setFontType('bold');
        doc.setFontSize(12);
        if (r === -1) {
          doc.text('(10) Quantity (Extra)', 4, 137);
        } else {
          doc.text('(10) Quantity ' + extraLabel, 4, 137);
        }
        doc.setFontType('unbold');
        doc.setFontSize(14);
        if (remaining > 0)
          doc.text(info.QuantityPerBox + " PIECES", 5, 147);
        else if (r === -1) {

        }
        else
          doc.text(r + " PIECES", 5, 147);



        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(11) Description', 72, 137);
        doc.setFontSize(14);
        doc.setFontType('unbold');
        const splitTitle2 = doc.splitTextToSize(info.PO_Part_Name, 60);
        doc.text(splitTitle2, 75, 147);
        doc.line(2, 157, 145, 157);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(12) QC / OPERATOR NAME', 4, 162);
        doc.text('(14) Production Date', 72, 162);
        doc.text('Date', 75, 172);
        doc.text('Month', 87, 172);
        doc.text('Year', 105, 172);
        doc.text('M/C', 120, 172);
        doc.line(75, 177, 130, 177);
        doc.line(75, 177, 75, 192);
        doc.line(88, 177, 88, 192);
        doc.line(100, 177, 100, 192);
        doc.line(118, 177, 118, 192);
        doc.line(130, 177, 130, 192);
        doc.line(75, 192, 130, 192);
        doc.line(2, 205, 145, 205);

        if (index <= (boxNeeded + extraBoxNeeded) + 1) {
          doc.addPage();
          if (extraLabel === '(Take From Stock)') {
            if (remaining <= 0) {
              remaining = -1;
            }
          }
          else if (remaining <= 0) {
            remaining = info.POQuantity - info.PO_Part_Qty;
            extraLabel = "(Take From Stock)"
          }
        }
      }
      doc.text('Please take from the stock', 10, 10);
      const col = ['PO Number/Old Stock', 'Part Number', 'Quantity'];
      const rows = [];
      info.TakeFromStocks.forEach(e => {
        const row = [e.BoxNumber.split('@@')[0], e.BoxNumber.split('@@')[1], e.DeductedQuantity];
        rows.push(row);
      })
      doc.autoTable(col, rows, { startY: 25 });



    } else {
      let remaining;
      let forecast = false;
      if(parseFloat(info.POQuantity) !== 0){
        remaining = parseFloat(info.POQuantity)
      }else{
        forecast = true;
        remaining = parseFloat(info.PO_Part_Qty)
      }


      var extraLabel = "";
      for (let index = 1; index <= extraBoxNeeded + 1; index++) {
        const qrcode = document.getElementById(index.toString());
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
        let r = remaining;
        remaining -= parseInt(info.QuantityPerBox);
        doc.setFontSize(12);
        doc.setLineWidth(0.2);
        doc.line(2, 2, 145, 2);    //horizontal line
        doc.line(2, 2, 2, 205);    //vertical line
        doc.line(145, 2, 145, 205);
        doc.line(70, 2, 70, 205);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(1) Receiver', 4, 7);
        doc.setFontType('unbold');
        doc.setFontSize(10);
        const text = info.CustomerName + "\n" + info.CustomerAddress;
        const splitTitle = doc.splitTextToSize(text, 60);
        doc.text(splitTitle, 4, 12);
        doc.setFontType('bold');
        doc.text('(2) Product Photo', 72, 7);
        const photo = document.createElement('img');
        photo.src = info.PartPhoto;
        doc.addImage(photo, 'PNG', 90, 7, 30, 30);
        doc.line(2, 39, 145, 39);
        doc.setFontType('bold');
        doc.text('(3) Invoice / Packing List No.' + extraLabel, 4, 45);

        doc.setFontType('unbold');
        doc.setFontSize(12);
        if (info.PackingListNo &&  extraLabel !== '(Extra)')
          doc.text("PL NO: "+ info.PackingListNo, 6, 55);

      if(pono){
        const d = doc.splitTextToSize("PO: "+pono, 70);
        doc.text(d, 6, 62);
      }
      if(info.Reference){
        const d = doc.splitTextToSize(info.Reference, 70);
        doc.text(d, 14, 69);
      }
      if(info.RevNO){
        const d = doc.splitTextToSize("REV: "+info.RevNO, 70);
        doc.text(d, 6, 76);
      }
      if(info.POS){
        const d = doc.splitTextToSize("POS: "+info.POS, 70);
        doc.text(d, 6, 83);
      }

        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text('(4) Supplier Address', 72, 45);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.setFontType('Tunga');
        doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
        doc.text('NO 12 & 14 LORONG PERINDUSTRIAN', 72, 55);
        doc.text('BUKIT MINYAK 5,', 72, 60);
        doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 65);
        doc.text('14100 BUKIT MERTAJAM,', 72, 70);
        doc.text('PULAU PINANG, WEST MALAYSIA', 72, 75);

        doc.line(70, 80, 145, 80);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(5) Net WT', 72, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(info.Part_Weight + ' GM', 77, 88);
        doc.line(91, 80, 91, 90);

        var crossWeight = (parseFloat(info.Part_Weight) * parseFloat(info.QuantityPerBox)+ parseFloat(info.Carton_Weight))/1000

        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(6) Gross WT', 92, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(crossWeight.toFixed(2) + ' KG', 97, 88);
        doc.line(120, 80, 120, 90);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(7) NO Boxes', 122, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        if (extraLabel !== '(Extra)')
          doc.text(index + ' of ' + boxNeeded, 126, 88);
        else {
          doc.text(index - boxNeeded + ' of ' + extraBoxNeeded, 126, 88);
        }
        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.line(2, 90, 145, 90);
        doc.text('(8) QR Code', 4, 95);
        doc.addImage(imageData, 'JPG', 10, 97);
        doc.text('(9) Customer Part NO (P)', 72, 95);
        doc.setFontType('bold');
        doc.setFontSize(18);
        const splt = doc.splitTextToSize(info.PO_Part_No, 60);
        doc.text(splt, 75, 105);
        doc.line(2, 132, 145, 132);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(10) Quantity ' + extraLabel, 4, 137);
        doc.setFontType('unbold');
        doc.setFontSize(14);
        if (remaining > 0)
          doc.text(info.QuantityPerBox + " PIECES", 5, 147);
        else if (r === -1 || (remaining <0 && extraLabel==="(Extra)")) {

        }
        else
          doc.text(r + " PIECES", 5, 147);



        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(11) Description', 72, 137);
        doc.setFontSize(14);
        doc.setFontType('unbold');
        const splitTitle2 = doc.splitTextToSize(info.PO_Part_Name, 60);
        doc.text(splitTitle2, 75, 147);
        doc.line(2, 157, 145, 157);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(12) QC / OPERATOR NAME', 4, 162);
        doc.text('(14) Production Date', 72, 162);
        doc.text('Date', 75, 172);
        doc.text('Month', 87, 172);
        doc.text('Year', 105, 172);
        doc.text('M/C', 120, 172);
        doc.line(75, 177, 130, 177);
        doc.line(75, 177, 75, 192);
        doc.line(88, 177, 88, 192);
        doc.line(100, 177, 100, 192);
        doc.line(118, 177, 118, 192);
        doc.line(130, 177, 130, 192);
        doc.line(75, 192, 130, 192);
        doc.line(2, 205, 145, 205);

        if (index <= extraBoxNeeded) {
          doc.addPage();
          if (extraLabel === '(Extra)') {
            if (remaining < parseInt(info.QuantityPerBox)) {
              remaining = -1;
            }
          }
          else if (remaining <= 0) {
            if(forecast){
              remaining = -1;

            }else{
              remaining = info.PO_Part_Qty - info.POQuantity;

            }
            extraLabel = "(Extra)"
          }
        }

      }
    }


    doc.save(pono+"_"+info.PO_Part_No + '_Label.pdf');
    return null;

  }

}
