import { RawMaterialOrder } from './../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-po-stock-record',
  templateUrl: './po-stock-record.component.html',
  styleUrls: ['./po-stock-record.component.css']
})
export class PoStockRecordComponent implements OnInit {

  filter:string ="All";
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    qrCodes = [];

    email:string;
    POlist: any[];
    poInfo : RawMaterialOrder;
    clone = [];
    constructor(
      private dialogRef: MatDialogRef<PoStockRecordComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService
    ) {
      this.POlist = data.slice();

      this.clone = this.POlist.slice();


    }

    ngOnInit() {

    }
    filterRecord(word){
      this.filter = word;
      this.POlist = this.clone.slice();
      if(word === 'Current stock')
        this.POlist = this.POlist.filter(po=> parseFloat(po.RawMaterial.PO_RawMaterial_Qty)
                                            - parseFloat(po.RawMaterial.Deducted_Quantity)
                                            !== 0 )
    }


    cancel() {
      this.dialogRef.close(false);
    }

    generate(stock: RawMaterialOrder){
      this.qrCodes = [];
      const value = {
        poID: this.poName,
        rawOrderIDs: stock.PO_Order_ID,
        materialIDs: stock.PO_RawMaterial_ID,
      };
      const qr = {
        id: 1,
        value: JSON.stringify(value)
      };
      this.qrCodes.push(qr);
      this.spinner.show();
      setTimeout(() => {
        const doc = new jsPDF();
        const qrcode = document.getElementById("1");
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
        doc.text('PO Name :' + this.poName, 20, 10);
        doc.text('Material Name :' + stock.PO_RawMaterial_Name, 20, 18);
        doc.text('Lot Number :' + stock.ReceivedDetailsList[0].Lot_Number, 20, 26);
        doc.text('Rack Number :' + stock.ReceivedDetailsList[0].Rack_Number, 20, 34);
        doc.addImage(imageData, 'JPG', 20, 36);
        doc.save(stock.PO_RawMaterial_Name+'QR.pdf');
      this.spinner.hide();

      }, 1000);

    }

    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    }

    poName: string;
    view(stock){
      this.poInfo = stock.RawMaterial
      this.poName = stock.PONumber
    }


}
