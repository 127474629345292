import { NgxSpinnerService } from 'ngx-spinner';
import { ViewRawMaterialsComponent } from './../../../Shared/view-raw-materials/view-raw-materials.component';
import { ConfirmationDialogComponent } from './../../../Shared/confirmation-dialog/confirmation-dialog.component';
import { ScheduleComponent } from './../../schedule/schedule.component';
import { SelectMachineSlotComponent } from './select-machine-slot/select-machine-slot.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from '../../../Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from '../../../Services/Object_Classes/Part/Part';
import { ScheduleTracker, Machine } from '../../../Services/Object_Classes/Machine/Machine';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {
  poNo: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  part: Part = new Part();
  machine: Machine = new Machine();
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  po_controller: PODB_controller = new PODB_controller(this.db);
  machineList: Machine[] = [];
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  partlist: Part[] = [];
  matSelectPart: Part[] = [];
  SelectPart2 = []
  SelectPart1 = []
  clone: Part[] = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  newPO: PurchaseOrder = new PurchaseOrder();
  newPO_PartList: PartTracker[] = [];
  startDate = [];
  endDate = [];
  searchPart: any;
  check = false;
  forecastChecked = false;
  email: string;
  formvalid = [];
  @ViewChild('picker', { static: true }) picker: any;

  public date: moment.Moment;
  public minDate: Date;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  private dateFormat = new DateFormatService();

  constructor(
    private dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,


  ) {
    this.addForm = this.fb.group({
      name: '',
      parts: this.fb.array([]),
    });
    this.minDate = new Date();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
    this.addParts();

  }
  existingName = false


  async nameValidation() {
    const name = this.addForm.get('name').value;
    var snapshot = await this.db.database.ref('Purchase Order/' + name).once('value');
    if (snapshot.exists()) {
      this.existingName = true;
    }
    else if (name.includes('/') || name.includes('@')) {
      this.existingName = true
    } else
      this.existingName = false;
  }

  ngOnInit() {
    this.spinner.show();
    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.clone = this.matSelectPart.slice();
      this.spinner.hide();

    });
  }

  change() {
    if (this.forecastChecked) {
      this.addForm.get('name').disable();
      this.addForm.get('name').setValue('');

    } else {
      this.addForm.get('name').enable();
    }
  }

  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }

  newParts(): FormGroup {

    const partControl = new FormControl();
    const part2Control = new FormControl();
    const part3Control = new FormControl();
    const partfilterControl = new FormControl();
    const part2filterControl = new FormControl();
    const part3filterControl = new FormControl();

    const pos = new FormControl();
    pos.disable();
    const pos2 = new FormControl();
    pos2.disable();
    const pos3 = new FormControl();
    pos3.disable();
    const reference = new FormControl();
    reference.disable();
    const reference2 = new FormControl();
    reference2.disable();
    const reference3 = new FormControl();
    reference3.disable();

    const poquantity2 = new FormControl();
    poquantity2.disable();
    const poquantity3 = new FormControl();
    poquantity3.disable();


    const index = this.parts().length;
    partfilterControl.valueChanges.subscribe((value) => {
      this.findPart(partfilterControl);
    });
    part2filterControl.valueChanges.subscribe((value) => {
      this.findPart2(part2filterControl, index);
    });
    part3filterControl.valueChanges.subscribe((value) => {
      this.findPart2(part3filterControl, index);
    });
    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        pos.enable();
        reference.enable();
        this.searchPart = partControl.value;
        this.check = true;
      }else{
        pos.setValue("")
        pos.disable();
        reference.setValue("")
        reference.disable();
      }
    });

    part2Control.valueChanges.subscribe(() => {
      this.part2[index] = part2Control.value;
      if(part2Control.value){
        pos2.enable();
        reference2.enable();
        poquantity2.enable();
      }else{
        pos2.setValue("")
        pos2.disable();
        reference2.setValue("")
        reference2.disable();
        poquantity2.disable();
        poquantity2.setValue('0');

      }

      if(part2Control.value && part3Control.value){
        if (this.addForm.get('parts').value[index].quantity) {
          const partInfo2 = part2Control.value?
          this.clone.find(p=>p.Part_No === part2Control.value): null;
          const partInfo3 = part3Control.value?
          this.clone.find(p=>p.Part_No === part3Control.value): null;
          this.viewRaw(this.partlist[index].Raw_Material, index, 3, partInfo2, partInfo3)
        }
      }else if(part2Control.value && !part3Control.value){
        if (this.addForm.get('parts').value[index].quantity) {
          const partInfo2 = part2Control.value?
          this.clone.find(p=>p.Part_No === part2Control.value): null;
          this.viewRaw(this.partlist[index].Raw_Material, index, 2,partInfo2)
        }
      }else if(!part2Control.value && part3Control.value){
        if (this.addForm.get('parts').value[index].quantity) {
          const partInfo3 = part3Control.value?
          this.clone.find(p=>p.Part_No === part3Control.value): null;
          this.viewRaw(this.partlist[index].Raw_Material, index, 2, null, partInfo3)
        }
      }else{
          if (this.addForm.get('parts').value[index].quantity) {
            this.viewRaw(this.partlist[index].Raw_Material, index, 1)
          }
      }
    });

    part3Control.valueChanges.subscribe(() => {
      this.part3[index] = part3Control.value;
      if(part3Control.value){
        pos3.enable();
        reference3.enable();
        poquantity3.enable();
      }else{
        pos3.setValue("")
        pos3.disable();
        reference3.setValue("")
        reference3.disable();
        poquantity3.disable();
        poquantity3.setValue('0');
      }
      if(part2Control.value && part3Control.value){
        if (this.addForm.get('parts').value[index].quantity) {
          const partInfo2 = part2Control.value?
          this.clone.find(p=>p.Part_No === part2Control.value): null;
          const partInfo3 = part3Control.value?
          this.clone.find(p=>p.Part_No === part3Control.value): null;
          this.viewRaw(this.partlist[index].Raw_Material, index, 3, partInfo2, partInfo3)
        }
      }else if(part2Control.value && !part3Control.value){
        if (this.addForm.get('parts').value[index].quantity) {
          const partInfo2 = part2Control.value?
          this.clone.find(p=>p.Part_No === part2Control.value): null;
          this.viewRaw(this.partlist[index].Raw_Material, index, 2,partInfo2)
        }
      }else if(!part2Control.value && part3Control.value){
        if (this.addForm.get('parts').value[index].quantity) {
          const partInfo3 = part3Control.value?
          this.clone.find(p=>p.Part_No === part3Control.value): null;
          this.viewRaw(this.partlist[index].Raw_Material, index, 2, null, partInfo3)
        }
      }else{
          if (this.addForm.get('parts').value[index].quantity) {
            this.viewRaw(this.partlist[index].Raw_Material, index, 1)
          }
      }
    });



    return this.fb.group({
      partControl,
      part2Control,
      part3Control,
      partfilterControl,
      part2filterControl,
      part3filterControl,
      partNumber: '',
      quantity: '',
      availableStock: '',
      raw: '',
      completeTime: '',
      availableMachineList: '',
      dateControl: new Date(),
      poquantity: '',
      poquantity2,
      poquantity3,
      pos,
      reference,
      pos2,
      reference2,
      pos3,
      reference3,
      flag:false,
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));
  }

  findPart2(part, index) {
    if (!this.SelectPart2[index]) { return; }

    this.SelectPart1[index] = this.SelectPart2[index].filter(p => p.Part_No.toLowerCase().includes(part.value.toLowerCase()));
  }

  part2=[];
  part3=[];

  addParts() {
    this.parts().push(this.newParts());
    this.availableMachineList.push(null);
    this.selectedMachineList.push(null);
    this.partlist.push(null);
    this.runningScheduleList.push(null);
    this.estimateTotalTime.push(null);
    this.startDate.push(null);
    this.endDate.push(null);
    this.formvalid.push(true);
    this.SelectPart1.push([]);
    this.SelectPart2.push([]);
    this.part2.push(null);
    this.part3.push(null);
    const info = {
      part: "",
      index: -1,
    }
    this.choosingPart.push(info);

  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.availableMachineList.splice(i, 1);
    this.partlist.splice(i, 1);
    this.runningScheduleList.splice(i, 1);
    this.estimateTotalTime.splice(i, 1);
    this.startDate.splice(i, 1);
    this.endDate.splice(i, 1);
    this.formvalid.splice(i, 1);
    this.SelectPart2.splice(i, 2);
    this.SelectPart1.splice(i, 2);
    this.part2.splice(i, 2);
    this.part3.splice(i, 2);
    this.choosingPart.splice(i,2);
  }

  cancel() {
    this.dialogRef.close(false);
  }


  confirm() {
    if (this.existingName) {
      this.toast.error('Invalid character("/@") inside the PO name ', 'Please change!');
      return;
    }
    if (this.dateFormat.checkCrushDate(this.startDate, this.endDate, this.selectedMachineList)) {
      this.toast.error('There a crush between new added part in machine scheduling', 'Please check');
      return;
    };
    const addPOModel = {
      POName: this.addForm.get('name').value,
      parts: []
    };
    const parts = this.addForm.get('parts').value;

    parts.forEach(async (element, index) => {
      if (!this.selectedMachineList[index]) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }
      const date = this.startDate[index];
      const endDate = this.endDate[index];
      if (this.forecastChecked)
        element.poquantity = 0;
      const info = {
        machineChosen: this.selectedMachineList[index].Machine_No,
        completeTime: this.estimateTotalTime[index],
        startTime: date,
        endTime: endDate,
        partNumber: element.partControl,
        partNumber2: this.part2[index],
        partNumber3: this.part3[index],
        partName: this.partlist[index].Part_Name,
        partName2: this.part2[index]?this.clone.find(p=>p.Part_No === this.part2[index]).Part_Name:null,
        partName3: this.part3[index]?this.clone.find(p=>p.Part_No === this.part3[index]).Part_Name:null,
        pos:element.pos,
        pos2:element.pos2,
        pos3:element.pos3,
        reference:element.reference,
        reference2:element.reference2,
        reference3:element.reference3,
        quantity: element.quantity,
        raw: this.partlist[index],
        scheduleID: uuidv4(),
        scheduleID2: uuidv4(),
        scheduleID3: uuidv4(),
        poquantity: element.poquantity,
        poquantity2: element.poquantity2,
        poquantity3: element.poquantity3,

      };
      if (!info.machineChosen || !info.completeTime || !(info.poquantity >= 0)
        || !info.partNumber || !info.partName || !info.quantity || !info.raw || !this.formvalid[index]) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }
      if(info.partName2){
        if(!info.poquantity2 && !this.forecastChecked){
          this.toast.error('Part information(' + (index + 1) + ') PO quantity #2 not completed!', 'Please fill in');
          return;
        }
      }
      if(info.partName3){
        if(!info.poquantity3 && !this.forecastChecked){
          this.toast.error('Part information(' + (index + 1) + ') PO quantity #2 not completed!', 'Please fill in');
          return;
        }
      }

      addPOModel.parts.push(info);
    });


    if (addPOModel.parts.length === 0) {
      this.toast.error('Please add at least one part', 'Add part');
      return;
    }
    if (!addPOModel.POName && !this.forecastChecked ) {
      this.toast.error('Please fill in the PO name', 'Please fill in');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this PO?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        if (!this.forecastChecked) {
          await this.po_controller.addPO(addPOModel, this.email);
          this.spinner.hide();

          this.dialogRef.close(addPOModel);
          this.toast.success('Add PO successful');
        } else {
          addPOModel.POName = this.getRandomString(5) + '-FORECAST';
          await this.po_controller.addPO(addPOModel, this.email);
          this.spinner.hide();

          this.dialogRef.close(addPOModel);
          this.toast.success('Add PO successful');
        }
      }
    });

  }

  getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  choosingPart = [];

  getMachineForPart(partNumber: any, formindex) {
    this.spinner.show();
    const samePart = this.choosingPart.find(c => c.part === partNumber && c.index !== formindex);
    if (samePart) {
      this.toast.warning("Same part number has been ordered, please choose others", "Warning");
      this.partlist[formindex] = null;
      this.availableMachineList[formindex]=null;
      this.spinner.hide();
      return;
    }
    const rawMaterial = this.clone.find(p => p.Part_No === partNumber).Raw_Material.find(r=>r.Raw_Type === 'Raw Material');

    if (rawMaterial) {
      this.SelectPart2[formindex] = this.clone.filter(
        p => String(p.Cycle_Time) === String(this.clone.find(p => p.Part_No === partNumber).Cycle_Time)
          && p.Raw_Material.find(r => r.Material_ID === rawMaterial.Material_ID))
          this.part2[formindex] = ''
    } else {
      this.SelectPart2[formindex] = []
      this.part3[formindex] = ''

    }

    this.SelectPart1[formindex] = this.SelectPart2[formindex].slice();

    const parts = this.parts().controls;
    parts[formindex].get('part2Control').setValue('')
    parts[formindex].get('part3Control').setValue('')

    if (partNumber) {
      this.part_controller.search_Part(partNumber).then(data => {
        this.partlist[formindex] = data;
        const info = {
          part: partNumber,
          index: formindex,
        }
        this.choosingPart[formindex] = info;
        this.machine_controller.getMachineListWithLastSchedule().then(data => {
          this.machineList = data;
          const list = [];
          const maclist = this.partlist[formindex].Available_Machine.split(',');
          maclist.forEach(machineno => {
            const result = this.machineList.find(m => m.Machine_No === 'Machine ' + machineno);
            if (result) {
              list.push(result);
            }
          });
          let previous, current, decide;
          list.forEach(machine => {
            if (machine.Schedule_Track.length !== 0) {
              machine.startDate = new Date(machine.Schedule_Track[0].Machine_Schedule_Start_Date);
              machine.endDate = new Date(machine.Schedule_Track[0].Machine_Schedule_End_Date);
              if (!previous) {
                previous = machine;
                decide = previous;
              } else {
                current = machine;
                if (new Date(current.Schedule_Track[0].Machine_Schedule_End_Date).getTime() <
                  new Date(previous.Schedule_Track[0].Machine_Schedule_End_Date).getTime()) {
                  decide = current;
                } else {
                  decide = previous;
                }
                previous = current;
              }
            }
          });

          this.availableMachineList[formindex] = list;
          if (decide) {

            this.selectedMachineList[formindex] = decide;
            this.startDate[formindex] = new Date(decide.Schedule_Track[0].Machine_Schedule_End_Date);
            if(this.startDate[formindex].getTime() < new Date().getTime()){
              this.startDate[formindex] =new Date();
            }
            this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);

          } else {
            this.startDate[formindex] = new Date();
            this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);

          }
        });
      });


    }
    this.spinner.hide();

  }

  selectSlot(event, i) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = event.value;

    this.dialog.open(SelectMachineSlotComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.dateslots[i] = result.date;
        this.timeslots[i] = result.slot;
      }
    });
  }

  estimateTime(partQuantity: any, formindex) {
    this.estimateTotalTime[formindex] = (this.partlist[formindex].Cycle_Time * (partQuantity / this.partlist[formindex].Number_Cavity)) / 3600;

    this.endDate[formindex] = new Date(this.startDate[formindex].getTime()
      + this.estimateTotalTime[formindex] * 60 * 60000);

    var mac = null;
    this.db.database.ref('Machine').child(this.selectedMachineList[formindex].Machine_No).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.startDate[formindex], this.endDate[formindex], mac)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid[formindex] = false;
          return;
        }
        else {
          this.formvalid[formindex] = true;
        }
      }
    })
    if (this.addForm.get('parts').value[formindex].quantity) {
      this.viewRaw(this.partlist[formindex].Raw_Material, formindex)
    }
  }


  selectMachine(formindex, machineindex) {
    this.selectedMachineList[formindex] = this.availableMachineList[formindex][machineindex];
    let endDate = new Date(this.selectedMachineList[formindex].Schedule_Track[0].Machine_Schedule_End_Date);
    this.startDate[formindex] = new Date(this.selectedMachineList[formindex].Schedule_Track[0].Machine_Schedule_End_Date);

    if(endDate.getTime() < new Date().getTime()){
      endDate = new Date();
      this.startDate[formindex] =new Date();
    }
    endDate.setHours(endDate.getHours() +1);
    this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);

    if(this.estimateTotalTime[formindex]){
      this.endDate[formindex] = new Date(
        endDate.getTime()
      + this.estimateTotalTime[formindex] * 60 * 60000);
    }

  }

  ChangeDate(formindex) {
    this.endDate[formindex] = new Date(this.startDate[formindex].getTime()
      + this.estimateTotalTime[formindex] * 60 * 60000);
    var mac = null;
    this.db.database.ref('Machine').child(this.selectedMachineList[formindex].Machine_No).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.startDate[formindex], this.endDate[formindex], mac)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid[formindex] = false;
          return;
        }
        else {
          this.formvalid[formindex] = true;

        }
      }
    })
  }
  viewRaw(materials, formindex, value = 0, part2 = null, part3 =null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    var quantity = this.addForm.get('parts').value[formindex].quantity;
    var partInfo = this.partlist[formindex];


    var multiple = value;
    let partInfo2 = part2;
    let partInfo3 = part3;
    if(value === 0){
      partInfo2 = this.addForm.get('parts').value[formindex].part2Control?
       this.clone.find(p=>p.Part_No === this.addForm.get('parts').value[formindex].part2Control): null;
      partInfo3 = this.addForm.get('parts').value[formindex].part3Control?
       this.clone.find(p=>p.Part_No === this.addForm.get('parts').value[formindex].part3Control): null;
       multiple = (this.addForm.get('parts').value[formindex].part2Control &&
       this.addForm.get('parts').value[formindex].part3Control) ? 3 :
       (this.addForm.get('parts').value[formindex].part2Control &&
         !this.addForm.get('parts').value[formindex].part3Control) ? 2 :
         (this.addForm.get('parts').value[formindex].part3Control &&
           !this.addForm.get('parts').value[formindex].part2Control) ? 2 :
           1
    }



    dialogConfig.data = { materials, quantity, partInfo, partInfo2 ,partInfo3,multiple};

    this.dialog.open(ViewRawMaterialsComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result.Material) {
        materials.forEach((element, index) => {
          element.check = result.Material[index].check;
          element.quantityNeeded = result.Material[index].quantityNeeded;
        });
        this.partlist[formindex].innerCheck = result.innerCheck;
        this.partlist[formindex].polyCheck = result.polyCheck;
        this.partlist[formindex].polyBagNeeded = result.polyBagNeeded;
        this.partlist[formindex].cartonNeeded = result.cartonNeeded;
        this.partlist[formindex].innerBoxNeeded = result.innerBoxNeeded;

      }
    });
  }



  parFloat(value) {
    return parseFloat(value);
  }

  previewPrice(raw, formindex) {
    var total = 0;
    total += parseFloat(this.partlist[formindex].CartonBox.Unit_Price) * parseFloat(this.partlist[formindex].cartonNeeded);

    raw.forEach((element, index) => {
      if (element.check) {
        total += parseFloat(element.Unit_Price) * parseFloat(element.quantityNeeded);
      }

    });

    if (this.partlist[formindex].innerCheck) {
      total += parseFloat(this.partlist[formindex].InnerBox.Unit_Price) * parseFloat(this.partlist[formindex].innerBoxNeeded);
    }
    if (this.partlist[formindex].polyCheck) {
      total += parseFloat(this.partlist[formindex].PolyBag.Unit_Price) * parseFloat(this.partlist[formindex].polyBagNeeded);
    }
    if (isNaN(total)) {
      return 'None'
    }

    return "RM" + total.toFixed(2);

  }

  checkStock(value){
    const part = this.clone.find(p=>p.Part_No === value);
    if(part){
      this.toast.info("Stock quantity: " + part.Stock_Quantity,'Part No: ' +part.Part_No,{
        positionClass: 'toast-top-center'
     })
    }

  }

  tooltips(value){
    const part = this.clone.find(p=>p.Part_No === value);
    if(part){
      return "Stock quantity: " + part.Stock_Quantity
    }
  }
}
