import { reduce, startWith } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DetailsComponent } from '../details/details.component';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit {

  stock: any[] = [];
  reservedStock = [];
  type : any;
  notQc: any = [];
  cloneStock: any[] = []
  constructor(
    private dialogRef: MatDialogRef<StocksComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private changeDef: ChangeDetectorRef

  ) {
    this.type = data.type;
    if (data.type === 'stock') {
      this.db.database.ref('Part/' + data.Part_No).once('value').then(datasnapshot => {
        datasnapshot.child('Stock').forEach(e => {
          const info = {
            id: e.key,
            rackingNo:e.key.split('@@')[2] || '',
            warehouseLocation:e.key.split('@@')[3] || '',
            value: e.val()
          }
          this.db.database.ref('QC/'+e.key.split('@@')[0]).once('value').then(qc=>{
            if(qc.exists()){
              qc.forEach(child=>{
                if(child.key === data.Part_No){
                  info.rackingNo = child.child('Weighting').val()['RackingNo'];
                  info.warehouseLocation = child.child('Weighting').val()['WarehouseLocation'];
                  this.stock.push(info)
                  this.cloneStock = cloneDeep(this.stock);
                }
              })
            }else{
              this.stock.push(info)
              this.cloneStock = cloneDeep(this.stock);
            }
          })
        })
        this.spinner.hide();
      })
    }
    else if (data.type === 'reserved') {
      this.reservedStock = data.stock;
      this.notQc = data.notQc;
      for (const stk of this.stock) {
        this.db.database.ref('QC/'+stk.id.split('@@')[0]).once('value').then(qc=>{
          if(qc.exists()){
            qc.forEach(child=>{
              if(child.key === data.Part_No){
                stk.rackingNo = child.child('Weighting').val()['RackingNo'];
                stk.warehouseLocation = child.child('Weighting').val()['WarehouseLocation'];

              }
            })
          }
        })
      }
      this.spinner.hide();

    }

  }

  back(){
    this.display = false;
  }

  display = false;
  viewDetail(data){
    this.stock = this.reservedStock.filter(e=> e.poNumber === data.poNumber).slice();
    this.display = true;
  }

  findDetail(data){
    return this.reservedStock.find(e=> e.poNumber === data.poNumber)? true:false;
  }

  returnColor(data){
    const sto = this.reservedStock.filter(e=> e.poNumber === data.poNumber).slice();
    let value = 0;
    for (const s of sto) {
      value += s.value;
    }
    if(value === parseFloat(data.value))
      return 'green';
    else
      return 'red';
  }

  ngOnInit() {
    this.spinner.show();
  }
  cancel() {
    this.dialogRef.close(false);
  }

  update(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update old stock information?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        for (const s of this.cloneStock) {
          await this.db.database.ref('Part/' + s.id.split('@@')[1] + '/Stock/' + s.id).set(null);
        }

        for (const s of this.stock) {
          let path = s.id.split('@@')[0] + '@@' +  s.id.split('@@')[1] + '@@' + s.rackingNo + '@@' + s.warehouseLocation;
          this.db.database.ref('Part/' + s.id.split('@@')[1] + '/Stock/' + path).set(s.value);
        }
        this.spinner.hide();
        this.dialogRef.close(true);
      }
    });



  }

}
