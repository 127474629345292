import { TakeFromStock } from './../Object_Classes/PackingList/PackingList';
import { AngularFireDatabase } from '@angular/fire/database';
import { Invoice } from '../Object_Classes/Invoice/Invoice';
import { PackingList, PackingList_PO_List, PackingList_PO_Part_List } from '../Object_Classes/PackingList/PackingList';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';


export class PackingListDB_controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }


  /************** PackingList ****************/
  async getPackingListList(): Promise<PackingList[]> {
    let PackingListList: PackingList[] = [];
    var snapshot = await this.db.database.ref('PackingList').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let packinglist = new PackingList();
        let packinglist_polistl: PackingList_PO_List[] = [];
        packinglist.PackingList_No = childSnapshot.key;
        packinglist.Destination = childSnapshot.child('Destination').val();
        packinglist.Currency = childSnapshot.child('Currency').val();
        packinglist.customerID = childSnapshot.child('Customer').val();
        packinglist.AddedToInvoice = childSnapshot.child('Added To Invoice').val();
        packinglist.Shipping_Date = childSnapshot.child('Shipping Date').val();
        packinglist.Status = childSnapshot.child('Status').val();

        if (packinglist.customerID) {
          this.getCustomer(packinglist, packinglist.customerID);
        }
        childSnapshot.child('POList').forEach((childSnapshot2) => {
          let packinglist_polist = new PackingList_PO_List;
          let packinglist_popartlistl: PackingList_PO_Part_List[] = [];
          packinglist_polist.PO_No = childSnapshot2.key;

          childSnapshot2.forEach((childSnapshot3) => {
            let packinglist_popartlist = new PackingList_PO_Part_List;
            packinglist_popartlist.Part_No = childSnapshot3.key;
            packinglist_popartlist.Part_Quantity = childSnapshot3.child("Part Quantity").val();
            packinglist_popartlist.POQuantity = childSnapshot3.child("PO Quantity").val();

            childSnapshot3.child("Take From Stocks").forEach(stock => {
              const sto = new TakeFromStock();
              sto.BoxNumber = stock.key;
              sto.OriginalQuantity = stock.child('Original Quantity').val();
              sto.DeductedQuantity = stock.child('Deducted Quantity').val();
              packinglist_popartlist.TakeFromStocks.push(sto);

            })
            this.getPO(packinglist_popartlist, packinglist_polist.PO_No);
            this.getPartInfo(packinglist_popartlist);
            packinglist_popartlistl.push(packinglist_popartlist);
          });
          packinglist_polist.PackingList_PO_Part_List = packinglist_popartlistl;
          packinglist_polistl.push(packinglist_polist);
        });
        packinglist.PackingList_PO_List = packinglist_polistl;
        PackingListList.push(packinglist);
      });
    }
    return PackingListList;
  }
  private dateService = new DateFormatService();

  add_or_update_PackingList(_newPackingList: any, email: string, updateInvoice: boolean, sameRowInvoice: Invoice) {
    var updates = {};
    const info = {
      email: email,
      packinglist: _newPackingList.PackingListName,
      info: {},
      date: new Date()
    };
    const dateFormat = this.dateService.convertDateIntoYearMonthDay(new Date());
    this.firestore.collection('PackingList').doc(dateFormat).set({ Date: new Date() });
    const infos = []
    _newPackingList.parts.forEach(data => {
      infos.push(data.poNumber);
      infos.push(data.partNumber);
      updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/Part Quantity"] = data.productionQuantity;
      updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/PO Quantity"] = data.poQuantity;
      updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/Remaining Quantity"] = data.remainingQuantity;
      if (data.stocks) {
        var quantity = 0;
        data.stocks.forEach((element, ind) => {
          infos.push(ind);
          infos.push(element.Quantity);
          infos.push(element.DeductQuantity);

          updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/Take From Stocks/" + element.BoxNumber + '/Original Quantity'] = element.Quantity;
          updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/Take From Stocks/" + element.BoxNumber + '/Deducted Quantity'] = element.DeductQuantity;

          this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List').once('value').then(datasnap => {
            datasnap.forEach(po => {
              if (po.child('Part No').val() === data.partNumber) {
                this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/' + po.key + "/Take From Stocks/" + element.BoxNumber).set(element.DeductQuantity);
                this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/' + po.key + "/PO_Stock/" + element.BoxNumber).set(element.DeductQuantity);
              }
            })
          })

          this.db.database.ref('Part/' + data.partNumber).once('value').then(datasnap => {
            if (quantity === 0)
              quantity = parseFloat(datasnap.child('Stock Quantity').val());
            let Stockquantity = parseFloat(datasnap.child('/Stock/' + element.BoxNumber).val());
            Stockquantity -= parseFloat(element.DeductQuantity);

            quantity -= parseFloat(element.DeductQuantity);
            if (Stockquantity === 0) {
              this.db.database.ref('Part/' + data.partNumber + '/Stock/' + element.BoxNumber).set(null);
            } else {
              this.db.database.ref('Part/' + data.partNumber + '/Stock/' + element.BoxNumber).set(Stockquantity);
            }
            this.db.database.ref('Part/' + data.partNumber + '/Stock Quantity').set(quantity);

            const d1 = this.dateService.convertDateIntoYearMonthDay(new Date());
            const info1 = {
              BeforeQuantity: datasnap.child('Stock Quantity').val(),
              AfterQuantity: quantity,
              date: new Date()

            }
            this.firestore.collection('StockQuantity').doc(d1).set({ Date: new Date() });
            this.firestore.collection('StockQuantity').doc(d1).collection(data.partNumber).add(info1);

          })

          this.db.database.ref('QC/' + element.BoxNumber.split('@@')[0] +
            '/' + data.partNumber + '/Weighting/').once('value').then(datasnap => {
              var packagingQty = parseFloat(datasnap.child('MakeToStock').val());
              var deductQty = packagingQty - parseFloat(element.DeductQuantity);
              this.db.database.ref('QC/' + element.BoxNumber.split('@@')[0] +
                '/' + data.partNumber + '/Weighting/MakeToStock').set(deductQty);
              this.db.database.ref('QC/' + element.BoxNumber.split('@@')[0] +
                '/' + data.partNumber + '/Weighting/Taken by PO/' + data.poNumber).set(element.DeductQuantity);
            })

        });
      }
      // updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/Shipping Date"] = _newPackingList.ShipDate;
      // updates["/" + _newPackingList.PackingListName + "/POList/" + data.poNumber + "/" + data.partNumber + "/Status"] = 'Created';
      this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/').once('value').then(snapshot => {
        snapshot.forEach(chidlsnapshot => {
          if (chidlsnapshot.child('Part No').val() === (data.partNumber)) {
            this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/' + chidlsnapshot.key + '/Added to packing').set(true);

          }
        })
      })
    });
    updates["/" + _newPackingList.PackingListName + '/Destination'] = _newPackingList.Destination;
    updates["/" + _newPackingList.PackingListName + '/Customer'] = _newPackingList.customer;
    updates["/" + _newPackingList.PackingListName + '/Currency'] = _newPackingList.currency;
    updates["/" + _newPackingList.PackingListName + "/Shipping Date"] = _newPackingList.ShipDate;
    updates["/" + _newPackingList.PackingListName + '/_Created_Date'] = new Date();
    updates["/" + _newPackingList.PackingListName + '/_Updated_Date'] = new Date();
    updates["/" + _newPackingList.PackingListName + '/_Created_By'] = email;
    updates["/" + _newPackingList.PackingListName + '/_Updated_By'] = email;
    updates["/" + _newPackingList.PackingListName + '/Added To Invoice'] = false;
    updates["/" + _newPackingList.PackingListName + '/Status'] = 'Created';


    info.info = infos;
    this.firestore.collection('PackingList').doc(dateFormat).collection('Create').add(info);

    if (updateInvoice) {
      this.db.database.ref('Invoice/' + sameRowInvoice.Invoice_No + '/Total Price').set(sameRowInvoice.Total_Price);
    }

    this.db.database.ref('PackingList').update(updates);
  }

  updatePackinglist(_newPackingList, packingNumber, email) {
    var updates = {};
    _newPackingList.parts.forEach(data => {
      updates["/" + packingNumber + "/POList/" + data.poNumber + "/" + data.partNumber + "/Part Quantity"] = data.productionQuantity;
      updates["/" + packingNumber + "/POList/" + data.poNumber + "/" + data.partNumber + "/PO Quantity"] = data.poQuantity;
      updates["/" + packingNumber + "/POList/" + data.poNumber + "/" + data.partNumber + "/Remaining Quantity"] = data.remainingQuantity;
      if (data.stocks) {
        var quantity = 0;
        data.stocks.forEach(element => {
          updates["/" + packingNumber + "/POList/" + data.poNumber + "/" + data.partNumber + "/Take From Stocks/" + element.BoxNumber + '/Original Quantity'] = element.Quantity;
          updates["/" + packingNumber + "/POList/" + data.poNumber + "/" + data.partNumber + "/Take From Stocks/" + element.BoxNumber + '/Deducted Quantity'] = element.DeductQuantity;

          this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List').once('value').then(datasnap => {
            datasnap.forEach(po => {
              if (po.child('Part No').val() === data.partNumber) {
                this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/' + po.key + "/Take From Stocks/" + element.BoxNumber).set(element.DeductQuantity);
                this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/' + po.key + "/PO_Stock/" + element.BoxNumber).set(element.DeductQuantity);

              }
            })
          })

          this.db.database.ref('Part/' + data.partNumber).once('value').then(datasnap => {
            if (quantity === 0)
              quantity = parseFloat(datasnap.child('Stock Quantity').val());
            let Stockquantity = parseFloat(datasnap.child('/Stock/' + element.BoxNumber).val());
            Stockquantity -= parseFloat(element.DeductQuantity);

            quantity -= parseFloat(element.DeductQuantity);
            if (Stockquantity === 0) {
              this.db.database.ref('Part/' + data.partNumber + '/Stock/' + element.BoxNumber).set(null);
            } else {
              this.db.database.ref('Part/' + data.partNumber + '/Stock/' + element.BoxNumber).set(Stockquantity);
            }
            this.db.database.ref('Part/' + data.partNumber + '/Stock Quantity').set(quantity);

            const d1 = this.dateService.convertDateIntoYearMonthDay(new Date());
            const info1 = {
              BeforeQuantity: datasnap.child('Stock Quantity').val(),
              AfterQuantity: quantity,
              date: new Date()

            }
            this.firestore.collection('StockQuantity').doc(d1).set({ Date: new Date() });
            this.firestore.collection('StockQuantity').doc(d1).collection(data.partNumber).add(info1);

          })

          this.db.database.ref('QC/' + element.BoxNumber.split('@@')[0] +
            '/' + data.partNumber + '/Weighting/').once('value').then(datasnap => {
              var packagingQty = parseFloat(datasnap.child('MakeToStock').val());
              var deductQty = packagingQty - parseFloat(element.DeductQuantity);
              this.db.database.ref('QC/' + element.BoxNumber.split('@@')[0] +
                '/' + data.partNumber + '/Weighting/MakeToStock').set(deductQty);
              this.db.database.ref('QC/' + element.BoxNumber.split('@@')[0] +
                '/' + data.partNumber + '/Weighting/Taken by PO/' + data.poNumber).set(element.DeductQuantity);
            })

        });
      }
      // updates["/" + packingNumber + "/POList/" + data.poNumber + "/" + data.partNumber + "/Status"] = 'Created';
      this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/').once('value').then(snapshot => {
        snapshot.forEach(chidlsnapshot => {
          if (chidlsnapshot.child('Part No').val() === (data.partNumber)) {
            this.db.database.ref('Purchase Order/' + data.poNumber + '/Part List/' + chidlsnapshot.key + '/Added to packing').set(true);
          }
        })
      })
    });
    updates["/" + packingNumber + "/Shipping Date"] = _newPackingList.ShipDate;
    updates["/" + packingNumber + '/_Updated_Date'] = new Date();
    updates["/" + packingNumber + '/_Updated_By'] = email;

    this.db.database.ref('PackingList').update(updates).then(async e => {
      const snapshot = await this.db.database.ref('/PackingList/' + packingNumber + '/POList').once('value')
      if (!snapshot.exists()) {
        await this.db.database.ref('/PackingList/' + packingNumber).set(null)
      }
    });



  }

  async search_PackingList(PackingList_No: string): Promise<PackingList> {
    let searched_packinglist = new PackingList;
    let packinglist_polistl: PackingList_PO_List[] = [];
    var snapshot = await this.db.database.ref('PackingList').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        var get_PackingList_No = childSnapshot.key;

        if (PackingList_No.match(get_PackingList_No)) {
          childSnapshot.child('POList').forEach((childSnapshot2) => {
            let packinglist_polist = new PackingList_PO_List;
            let packinglist_popartlistl: PackingList_PO_Part_List[] = [];
            packinglist_polist.PO_No = childSnapshot2.key;
            searched_packinglist.PackingList_No = childSnapshot.key;
            searched_packinglist.Destination = childSnapshot.child('Destination').val();
            searched_packinglist.Currency = childSnapshot.child('Currency').val();
            searched_packinglist.customerID = childSnapshot.child('Customer').val();
            searched_packinglist.Status = childSnapshot.child('Status').val();
            searched_packinglist.AddedToInvoice = childSnapshot.child('Added To Invoice').val();
            searched_packinglist.Shipping_Date = childSnapshot.child('Shipping Date').val();

            if (searched_packinglist.customerID) {
              this.getCustomer(searched_packinglist, searched_packinglist.customerID);
            }
            childSnapshot2.forEach((childSnapshot3) => {
              let packinglist_popartlist = new PackingList_PO_Part_List;
              packinglist_popartlist.Part_No = childSnapshot3.key;
              packinglist_popartlist.Part_Quantity = childSnapshot3.child("Part Quantity").val();
              packinglist_popartlist.POQuantity = childSnapshot3.child("PO Quantity").val();
              // packinglist_popartlist.Shipping_Date = new Date(childSnapshot3.child("Shipping Date").val());
              childSnapshot3.child("Take From Stocks").forEach(stock => {
                const sto = new TakeFromStock();
                sto.BoxNumber = stock.key;
                sto.OriginalQuantity = stock.child('Original Quantity').val();
                sto.DeductedQuantity = stock.child('Deducted Quantity').val();
                packinglist_popartlist.TakeFromStocks.push(sto);
              })
              this.getPartInfo(packinglist_popartlist);

              packinglist_popartlistl.push(packinglist_popartlist);
            });
            packinglist_polist.PackingList_PO_Part_List = packinglist_popartlistl;
            packinglist_polistl.push(packinglist_polist);
          });
        }
        searched_packinglist.PackingList_PO_List = packinglist_polistl;
      });
    }

    return searched_packinglist;
  }


  async search_Partial_PackingList(PackingList_No: string): Promise<PackingList[]> {
    let PackingListList: PackingList[] = [];
    var snapshot = await this.db.database.ref('PackingList').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let searched_packinglist = new PackingList();
        let packinglist_polistl: PackingList_PO_List[] = [];
        var get_PackingList_No = childSnapshot.key;
        if (get_PackingList_No.includes(PackingList_No)) {
          childSnapshot.child('POList').forEach((childSnapshot2) => {
            let packinglist_polist = new PackingList_PO_List;
            let packinglist_popartlistl: PackingList_PO_Part_List[] = [];
            packinglist_polist.PO_No = childSnapshot2.key;
            searched_packinglist.PackingList_No = childSnapshot.key;
            searched_packinglist.Status = childSnapshot.child('Status').val();
            searched_packinglist.Destination = childSnapshot.child('Destination').val();
            searched_packinglist.Currency = childSnapshot.child('Currency').val();
            searched_packinglist.AddedToInvoice = childSnapshot.child('Added To Invoice').val();
            searched_packinglist.Shipping_Date = childSnapshot.child('Shipping Date').val();
            searched_packinglist.customerID = childSnapshot.child('Customer').val();
            if (searched_packinglist.customerID) {
              this.getCustomer(searched_packinglist, searched_packinglist.customerID);
            }
            childSnapshot2.forEach((childSnapshot3) => {
              let packinglist_popartlist = new PackingList_PO_Part_List;
              packinglist_popartlist.Part_No = childSnapshot3.key;
              packinglist_popartlist.Part_Quantity = childSnapshot3.child("Part Quantity").val();
              packinglist_popartlist.POQuantity = childSnapshot3.child("PO Quantity").val();
              // packinglist_popartlist.Shipping_Date = new Date(childSnapshot3.child("Shipping Date").val());
              childSnapshot3.child("Take From Stocks").forEach(stock => {
                const sto = new TakeFromStock();
                sto.BoxNumber = stock.key;
                sto.OriginalQuantity = stock.child('Original Quantity').val();
                sto.DeductedQuantity = stock.child('Deducted Quantity').val();
                packinglist_popartlist.TakeFromStocks.push(sto);
              })
              this.getPartInfo(packinglist_popartlist);
              packinglist_popartlistl.push(packinglist_popartlist);
            });
            packinglist_polist.PackingList_PO_Part_List = packinglist_popartlistl;
            packinglist_polistl.push(packinglist_polist);
          });
        }
        searched_packinglist.PackingList_PO_List = packinglist_polistl;
        PackingListList.push(searched_packinglist);
      });
    }

    return PackingListList;
  }


  delete_PackingList(packinglist: PackingList) {

    const dateFormat = this.dateService.convertDateIntoYearMonthDay(new Date());
    const info = {
      PackingListNo: packinglist.PackingList_No
    }
    this.firestore.collection('PackingList').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('PackingList').doc(dateFormat).collection('Delete').add(info);



    packinglist.PackingList_PO_List.forEach(data => {
      data.PackingList_PO_Part_List.forEach(async data2 => {
        await this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/').once('value').then(snapshot => {
          snapshot.forEach(chidlsnapshot => {
            if (chidlsnapshot.child('Part No').val() === (data2.Part_No)) {
              this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/Added to packing').set(false);
              this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/Take From Stocks').set(null);
              this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/PO_Stock').set(null);
            }
          })
        })
        if (data2.TakeFromStocks.length > 0) {
          var quantity = 0;
          data2.TakeFromStocks.forEach(async stock => {
            await this.db.database.ref('Part/' + data2.Part_No).once('value').then(snapshot => {
              if (quantity === 0)
                quantity = snapshot.child('Stock Quantity').val();
              let reservedQuantity = stock.DeductedQuantity;
              let stockQuantity = parseFloat(snapshot.child('Stock/' + stock.BoxNumber).val())
              if (!stockQuantity)
                stockQuantity = reservedQuantity;
              else
                stockQuantity += reservedQuantity;
              quantity += reservedQuantity;
              this.db.database.ref('Part/' + data2.Part_No + '/Stock Quantity').set(quantity);
              this.db.database.ref('Part/' + data2.Part_No + '/Stock/' + stock.BoxNumber).set(stockQuantity);
              const d1 = this.dateService.convertDateIntoYearMonthDay(new Date());
              const info1 = {
                BeforeQuantity: snapshot.child('Stock Quantity').val(),
                AfterQuantity: stockQuantity,
                date: new Date()

              }
              this.firestore.collection('StockQuantity').doc(d1).set({ Date: new Date() });
              this.firestore.collection('StockQuantity').doc(d1).collection(data2.Part_No).add(info1);

            })

            this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
              '/' + data2.Part_No + '/Weighting/').once('value').then(datasnap => {
                var packagingQty = parseFloat(datasnap.child('MakeToStock').val());
                var deductQty = packagingQty + stock.DeductedQuantity;
                this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                  '/' + data2.Part_No + '/Weighting/MakeToStock').set(deductQty);
                this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                  '/' + data2.Part_No + '/Weighting/Taken by PO/' + data.PO_No).set(null);
              })

            // this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
            // '/' + data2.Part_No + '/Weighting/' + stock.BoxNumber).once('value').then(datasnap=>{
            //   var packagingQty = parseFloat(datasnap.child('Packaging Qty').val());
            //   var deductQty = packagingQty + stock.DeductedQuantity;
            //   this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
            //   '/' + data2.Part_No + '/Weighting/' + stock.BoxNumber +'/Packaging Qty').set(deductQty);
            //   this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
            //   '/' + data2.Part_No + '/Weighting/' + stock.BoxNumber +'/Taken by PO/' + data.PO_No).set(null);

            // })
          })



        }

      });
    });
    this.db.database.ref('/PackingList/' + packinglist.PackingList_No).set(null);
  }

  async getCustomer(pack: PackingList, customerId): Promise<void> {
    var snapshot = await this.db.database.ref('Customer').child(customerId).once('value');
    if (snapshot.exists()) {
      pack.Customer = snapshot.child('_Customer_Name').val();
    }
  }

  async getPartInfo(pt: PackingList_PO_Part_List): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(pt.Part_No).once('value');
    if (snapshot.exists()) {
      pt.Part_Price = snapshot.child('PPL CUS').val();
      pt.Part_Mould_Price = snapshot.child('PPL MOLD').val();
      pt.Part_Remark = snapshot.child('Remarks').val();
      pt.Part_Name = snapshot.child('Part Name').val();
      pt.Photo = snapshot.child('PhotoURL').val();
      pt.Part_Weight = snapshot.child('Part Weight').val();
      pt.QuantityPerBox = snapshot.child('Packaging/Box/Quantity in Box').val();
      pt.InStock = snapshot.child('Stock Quantity').val();
      pt.RevNO = snapshot.child('RevNO').val()
      var snapshot2 = await this.db.database.ref('RawMaterial').child(snapshot.child('Packaging/Box/Box Size').val()).once('value');
      if(snapshot2.exists())
        pt.Carton_Weight = snapshot2.child('_Carton_Weight').val();

      const id = snapshot.child('CustomerID').val()
      if (id) {
        this.getCustomerInfo(id, pt);
      }
    }
  }
  async getCustomerInfo(id, pt: PackingList_PO_Part_List): Promise<void> {
    var cusSnapshot = await this.db.database.ref('Customer').child(id).once('value');
    if (cusSnapshot.exists()) {
      pt.CustomerName = cusSnapshot.child('_Customer_Name').val();
      pt.CustomerAddress = cusSnapshot.child('_Address').val();
      pt.partCurrency = cusSnapshot.child('_Currency').val();
    }
  }

  async getPO(pt: PackingList_PO_Part_List, poNo): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').child(poNo).once('value');
    if (snapshot.exists()) {
      snapshot.child('Part List').forEach(e => {
        if (e.child('Part No').val() === pt.Part_No) {
          pt.CartonBoxNeeded = e.child("Box/Carton/Quantity Needed").val();
          if (e.child('Sub').val()) {
            pt.Carton_Weight = snapshot.child("Part List/" + e.child('SubFrom').val() + "/Box/Carton/Carton Weight").val();
          } else {
            pt.Carton_Weight = e.child("Box/Carton/Carton Weight").val();

          }
        }
      })
    }
  }
}
