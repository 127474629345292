import { RawMaterialInfo } from './../RawMaterial/RawMaterial';

export class Part {
  private _Part_No: string;
  private _Part_Name: string;
  private _Cycle_Time: number;
  private _Number_Cavity: number;
  private Cavities: number;
  private _Available_Machine: string;
  private _Part_Weight: number;
  private _Wastage: number;
  private _Material_Code: string;
  private _Colour_Code: string;
  private _Colour_Ratio: number;
  private _Packing_Quantity: number;
  private _Packing_Quantity_Per_Carton: string;
  private _Product_Weight: number;
  private _Product_Width: number;

  private _Customer_License: string;
  private _DoP_Update: Date;
  private _DoR: Date;
  private _Duty_Export: number;
  private _FG_or_Semi: string;
  private _Machine_Tonnage: string;
  private _Part_Price: string;
  private _Part_Runner_Weight: number;
  private _Project_Start_Date: Date;
  private _Project_Status: string;
  private _Remarks: string;
  private _Stock_Quantity: number;
  private _Traffic_Code: string;
  private _Traffic_Code_Name: string;
  private _Customer_Name: string;
  private _Material: string;
  private _Color: string;
  private _PO_No: string;
  
  private _Box_Size: string;
  private _Box_Quantity: number;
  private _Polybag_Size: string;
  private _Polybag_Quantity: string;
  private _Raw_Material : RawMaterialInfo[];
  private _DrawingURL:string;
  private _PhotoURL:string;
  private _Quantity_InnerBox: string;
  private _Inner_BoxSize: string;
  private _Customer_Address: string;

  innerCheck: boolean;
  polyCheck:boolean;
  cartonNeeded:string;
  innerBoxNeeded:string;
  polyBagNeeded:string;
  customerCurrency:string;
  rackingNumber:string = '';

  public RevNO: string;
  public MaterialBatch : RawMaterialInfo[];
  public Pigment : RawMaterialInfo[];
  public CartonBox : RawMaterialInfo = new RawMaterialInfo();
  public PolyBag : RawMaterialInfo;
  public InnerBox : RawMaterialInfo;

  public POStocks : POStock[];
  public Reserved_Stock: number = 0;
  public ReservedInfo_NotQC = [];
  public ReservedInfo = [];

  get Customer_Address(): string {
    return this._Customer_Address;
  }

  set Customer_Address(value: string) {
    this._Customer_Address = value;
  }

  get Quantity_InnerBox(): string {
    return this._Quantity_InnerBox;
  }

  set Quantity_InnerBox(value: string) {
    this._Quantity_InnerBox = value;
  }

  get Inner_BoxSize(): string {
    return this._Inner_BoxSize;
  }

  set Inner_BoxSize(value: string) {
    this._Inner_BoxSize = value;
  }

  get DrawingURL(): string {
    return this._DrawingURL;
  }

  set DrawingURL(value: string) {
    this._DrawingURL = value;
  }

  get PhotoURL(): string {
    return this._PhotoURL;
  }

  set PhotoURL(value: string) {
    this._PhotoURL = value;
  }
  
  get Part_No(): string {
    return this._Part_No;
  }

  set Part_No(value: string) {
    this._Part_No = value;
  }

  get Part_Name(): string {
    return this._Part_Name;
  }

  set Part_Name(value: string) {
    this._Part_Name = value;
  }

  get Stock_Quantity(): number {
    return this._Stock_Quantity;
  }

  set Stock_Quantity(value: number) {
    this._Stock_Quantity = value;
  }

  get Customer_License(): string {
    return this._Customer_License;
  }

  set Customer_License(value: string) {
    this._Customer_License = value;
  }

  get DoP_Update(): Date {
    return this._DoP_Update;
  }

  set DoP_Update(value: Date) {
    this._DoP_Update = value;
  }

  get DoR(): Date {
    return this._DoR;
  }

  set DoR(value: Date) {
    this._DoR = value;
  }

  get Duty_Export(): number {
    return this._Duty_Export;
  }

  set Duty_Export(value: number) {
    this._Duty_Export = value;
  }

  get FG_or_Semi(): string {
    return this._FG_or_Semi;
  }

  set FG_or_Semi(value: string) {
    this._FG_or_Semi = value;
  }

  get Machine_Tonnage(): string {
    return this._Machine_Tonnage;
  }

  set Machine_Tonnage(value: string) {
    this._Machine_Tonnage = value;
  }

  get Available_Machine(): string {
    return this._Available_Machine;
  }
  
  set Available_Machine(value: string) {
    this._Available_Machine = value;
  }

  get Part_Price(): string {
    return this._Part_Price;
  }

  set Part_Price(value: string) {
    this._Part_Price = value;
  }

  get Part_Runner_Weight(): number {
    return this._Part_Runner_Weight;
  }

  set Part_Runner_Weight(value: number) {
    this._Part_Runner_Weight = value;
  }

  get Part_Weight(): number {
    return this._Part_Weight;
  }

  set Part_Weight(value: number) {
    this._Part_Weight = value;
  }

  get Wastage(): number {
    return this._Wastage;
  }

  set Wastage(value: number) {
    this._Wastage = value;
  }

  get Material_Code(): string {
    return this._Material_Code;
  }

  set Material_Code(value: string) {
    this._Material_Code = value;
  }

  get Colour_Code(): string {
    return this._Colour_Code;
  }

  set Colour_Code(value: string) {
    this._Colour_Code = value;
  }

  get Colour_Ratio(): number {
    return this._Colour_Ratio;
  }

  set Colour_Ratio(value: number) {
    this._Colour_Ratio = value;
  }

  get Packing_Quantity(): number {
    return this._Packing_Quantity;
  }

  set Packing_Quantity(value: number) {
    this._Packing_Quantity = value;
  }

  get Packing_Quantity_Per_Carton(): string {
    return this._Packing_Quantity_Per_Carton;
  }

  set Packing_Quantity_Per_Carton(value: string) {
    this._Packing_Quantity_Per_Carton = value;
  }

  get Product_Weight(): number {
    return this._Product_Weight;
  }

  set Product_Weight(value: number) {
    this._Product_Weight = value;
  }

  get Product_Width(): number {
    return this._Product_Width;
  }

  set Product_Width(value: number) {
    this._Product_Width = value;
  }

  get Project_Start_Date(): Date {
    return this._Project_Start_Date;
  }

  set Project_Start_Date(value: Date) {
    this._Project_Start_Date = value;
  }

  get Project_Status(): string {
    return this._Project_Status;
  }

  set Project_Status(value: string) {
    this._Project_Status = value;
  }

  get Remarks(): string {
    return this._Remarks;
  }

  set Remarks(value: string) {
    this._Remarks = value;
  }

  get Traffic_Code(): string {
    return this._Traffic_Code;
  }

  set Traffic_Code(value: string) {
    this._Traffic_Code = value;
  }

  get Traffic_Code_Name(): string {
    return this._Traffic_Code_Name;
  }

  set Traffic_Code_Name(value: string) {
    this._Traffic_Code_Name = value;
  }

  get Customer_Name(): string {
    return this._Customer_Name;
  }

  set Customer_Name(value: string) {
    this._Customer_Name = value;
  }

  get Material(): string {
    return this._Material;
  }

  set Material(value: string) {
    this._Material = value;
  }

  get Color(): string {
    return this._Color;
  }

  set Color(value: string) {
    this._Color = value;
  }

  get PO_No(): string {
    return this._PO_No;
  }

  set PO_No(value: string) {
    this._PO_No = value;
  }

  get Cycle_Time(): number {
    return this._Cycle_Time;
  }
  set
   Cycle_Time(value: number) {
    this._Cycle_Time = value;
  }

  get Number_Cavity(): number {
    return this._Number_Cavity;
  }

  set Number_Cavity(value: number) {
    this._Number_Cavity = value;
  }

  get Cavity(): number {
    return this.Cavities;
  }

  set Cavity(value: number) {
    this.Cavities = value;
  }

  get Box_Size(): string {
    return this._Box_Size;
  }

  set Box_Size(value: string) {
    this._Box_Size = value;
  }

  get Box_Quantity(): number {
    return this._Box_Quantity;
  }

  set Box_Quantity(value: number) {
    this._Box_Quantity = value;
  }

  get Polybag_Size(): string {
    return this._Polybag_Size;
  }

  set Polybag_Size(value: string) {
    this._Polybag_Size = value;
  }

  get Polybag_Quantity(): string {
    return this._Polybag_Quantity;
  }

  set Polybag_Quantity(value: string) {
    this._Polybag_Quantity = value;
  }

  get Raw_Material(): RawMaterialInfo[] {
    return this._Raw_Material;
  }

  set Raw_Material(value: RawMaterialInfo[]){
    this._Raw_Material = value;
  }
}

export class POStock {
  public BoxNumber: string;
  public Quantity: number;
}
