import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent implements OnInit {

  Staff = new Staff();
  currencyList = Currency;

  modulesList = ['Quality and Quantity Control', 'Part Management', 'Machine Monitoring', 'PO Management', 'Staff Management'];

  positionList = ['ADMIN', 'TECH DEPT', 'QA DEPT', 'LEADER', 'OPERATOR', 'INTERN'];

  createMode =true;
  pass : string;
  ngOnInit() {
  }
  constructor(
    private angularFireAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<AddStaffComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

      if(data){
        this.createMode  = false;
        this.Staff = data;
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.Staff);
  }

  emailFlag= false;
  passFlag= false;


  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  passwordChange(event, model: NgModel) {
    if(model.value.length < 6){
      model.control.setErrors({ invalid: true });
      this.passFlag = true;

    }else{
      this.passFlag = false;

    }
  }

  positionChange(Role){
    if(Role === 'ADMIN')
      this.Staff.Access = [];
  }


}
