import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ToastrService } from 'ngx-toastr';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-generate-qrcode',
  templateUrl: './generate-qrcode.component.html',
  styleUrls: ['./generate-qrcode.component.css']
})
export class GenerateQRCodeComponent implements OnInit {
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  email: string;
  POlist: PurchaseOrderRaw[];
  selectedPO: any = [];
  selectedRaw = [[]];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrCodes = [];
  dateFrom: Date;
  dateTo: Date;
  maxDate: Date;
  ClonePO: PurchaseOrderRaw[];
  search ='';
  constructor(
    private dialogRef: MatDialogRef<GenerateQRCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth
  ) {
    this.maxDate = new Date();
    this.dateFrom = new Date();
    this.dateFrom.setMonth(this.dateFrom.getMonth() - 1);
    this.dateTo = new Date();

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.PORawController.search_PO_withStatusCompleted().then(po => {
      this.POlist = po;
      this.ClonePO = this.POlist.slice();
    });
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.qrCodes = [];

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Generate QR code for these selected PO';
    let counter = 0;
    for (let i = 0; i < this.selectedPO.length; i++) {
      this.selectedRaw[i].forEach(element => {
        const value = {
          poID: this.selectedPO[i].PO_No,
          rawOrderIDs: element.PO_Order_ID,
          materialIDs: element.PO_RawMaterial_ID,
        };
        const qr = {
          id: counter,
          po: this.selectedPO[i].PO_No,
          raw: element.PO_RawMaterial_Name,
          detail:element.ReceivedDetailsList[0],
          value: JSON.stringify(value)
        };
        this.qrCodes.push(qr);
        counter++;
      });
    }
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const doc = new jsPDF();
        let d = 0;
        this.qrCodes.forEach((e, i) => {
          const qrcode = document.getElementById(i.toString());
          const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
          if ((i + 1) % (2 + d) === 0) {
            doc.text('PO Name :' + e.po, 20, 110);
            doc.text('Material Name :' + e.raw, 20, 118);
            doc.text('Lot Number :' + e.detail.Lot_Number, 20, 126);
            doc.text('Rack Number :' + e.detail.Rack_Number, 20, 134);
            doc.addImage(imageData, 'JPG', 20, 136);
          } else if ((i + 1) % (3 + d) === 0) {
            doc.text('PO Name :' + e.po, 20, 210);
            doc.text('Material Name :' + e.raw, 20, 218);
            doc.text('Lot Number :' + e.detail.Lot_Number, 20, 226);
            doc.text('Rack Number :' + e.detail.Rack_Number, 20, 234);
            doc.addImage(imageData, 'JPG', 20, 234);
            d = d + 3;
            if (this.qrCodes.length !== i + 1)
              doc.addPage();
          } else {
            doc.text('PO Name :' + e.po, 20, 10);
            doc.text('Material Name :' + e.raw, 20, 18);
            doc.text('Lot Number :' + e.detail.Lot_Number, 20, 26);
            doc.text('Rack Number :' + e.detail.Rack_Number, 20, 34);
            doc.addImage(imageData, 'JPG', 20, 36);
          }
        });
        doc.save('QR.pdf');
        this.dialogRef.close(true);
      }
    });
  }


  change(event) {
    const length = this.selectedPO.length;
    this.selectedRaw.length = length;
  }

  rawchange(event) {

  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  applyFilter(value){
    this.update(false)
    this.POlist = this.POlist.filter(p=>p.PO_No.toLowerCase().includes(value.toLowerCase()));
  }




  update(flag = true) {
    if(flag)
      this.search= '';
    this.POlist = this.ClonePO.filter(p => p.Completed_Date.getTime() >= this.dateFrom.getTime() &&
    p.Completed_Date.getTime() <= this.dateTo.getTime() )
  }


}
