import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { SelectMachineComponent } from './select-machine/select-machine.component';
import { AddEventComponent } from './add-event/add-event.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Calendar, CalendarOptions, createElement, FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { UpdateScheduleComponent } from './update-schedule/update-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css']
})
export class SchedulingComponent implements AfterViewInit {

  constructor(private angularFireDatabase: AngularFireDatabase,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    const name = Calendar.name;
  }

  machine: string;
  machinelist = [];
  eventlist = [];
  partController = new PartDB_controller(this.angularFireDatabase, this.storage,this.firestore);
  @ViewChild('draggable', { static: true }) draggableEl: ElementRef;
  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    lazyFetching: true,
    contentHeight: 'auto',
    height: "100%",
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth, timeGridWeek, timeGridDay, listMonth'
    },
    dayMaxEvents: true,
    eventClick: (info) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = '80%';
      dialogConfig.width = '80%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        id: info.event.id,
        info: info.event.groupId,
      }
      this.dialog.open(UpdateScheduleComponent, dialogConfig).afterClosed().subscribe(result => {
        this.spinner.show();
        this.calendarComponent.getApi().removeAllEvents();
        this.getSchedule();
      });
    },
    eventContent: (event, element) => {
      return element = {
        html: '<div data-toggle="tooltip" data-html="true" data-placement="top" title="' + event.event.extendedProps.description + '" class="col-12 row" >'
          + '<div style="width:25px"><img class="zoom" src="' + event.event.extendedProps.imageURL + '" width="25px" height="25px"></div>' + event.event.title +
          '</div>'
      };
    }

  };
  count = 0;
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    const db = this.angularFireDatabase.database.ref('Machine/');
    db.once('value', (snapshot) => {

      snapshot.forEach(v => {
        if (!v.key.includes('@@')) {

          const info = {
            machineNumber: v.key
          };
          this.machinelist.push(info);
        }
      });
      this.machinelist = this.machinelist.sort((a,b)=> {
        return (Number(a.machineNumber.match(/(\d+)/g)[0]) - Number((b.machineNumber.match(/(\d+)/g)[0])));})
      this.machine = this.machinelist[0].machineNumber;
      this.getSchedule();
    });
  }

  getSchedule() {
    const db = this.angularFireDatabase.database.ref('Machine/' + this.machine + '/Schedule');
    db.once('value', snapshot => {
      snapshot.forEach(element => {
        this.partController.search_Part(element.val()['Part No']).then(data => {
          var part = data;
          const d = {
            machine: this.machine,
            po: element.val()['PO No'],
            part: element.val()['Part No'],
          }
          const part2 = element.val()['LinkedPart2'];
          const part3 = element.val()['LinkedPart3'];
          this.calendarComponent.getApi().addEvent({
            title: element.val()['PO No'] + ', ' + element.val()['Part No'] + ', ' + element.val()['Expected Quantity'],
            imageURL: part.PhotoURL,
            description: 'PO No: ' + element.val()['PO No'] +
              '\nPart No: ' + part.Part_No +
              (part2? '\nLinked Part No: ' + part2 : '') +
              (part3? '\nLinked Part No: ' + part3 : '') +
              '\nQuantity: ' + element.val()['Expected Quantity'] +
              '\nStart Time: ' + new Date(element.val().startAt) +
              '\nEnd Time: ' + new Date(element.val().endAt),
            start: element.val().startAt,
            end: element.val().endAt,
            id: element.key,
            groupId: JSON.stringify(d),

          });
        })

      });

    });
    this.spinner.hide();

  }


  AddEvent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '95vw';

    const position = {
      top: '1%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddEventComponent, dialogConfig).afterClosed().subscribe(result => {
      this.spinner.show();
      this.calendarComponent.getApi().removeAllEvents();
      this.getSchedule();

    });

  }

  save() {
    const events = (this.calendarComponent.getApi().getEvents());
    this.spinner.show();

    events.forEach(e => {
      const result = this.eventlist.find(r => r.name === e.title);
      if (!result) {
        const info = {
          name: e.title,
          startAt: e.start,
          endAt: e.end,
          Code: '-',
          '_Machine_Schedule_Status ': 'In Progress'
        };
        const id = uuidv4();
        this.eventlist.push({
          ...info,
          key: id
        });
        const update = this.angularFireDatabase.database.ref('Machine/' + this.machine + '/Schedule/' + id);
        update.update({
          ...info
        });
      } else {
        const info = {
          name: e.title,
          startAt: e.start,
          endAt: e.end,
          Code: '-',
          '_Machine_Schedule_Status ': 'In Progress'
        };
        const update = this.angularFireDatabase.database.ref('Machine/' + this.machine + '/Schedule/' + result.key);
        update.update({
          ...info
        });
      }
    });

    this.spinner.hide();


  }

  selectMachine(value) {
    this.machine = value;
    this.spinner.show();
    this.calendarComponent.getApi().removeAllEvents();
    this.getSchedule();

  }

}
