import { CheckOut, RawCheckOut } from '../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-raw-check-out-information',
  templateUrl: './raw-check-out-information.component.html',
  styleUrls: ['./raw-check-out-information.component.css']
})
export class RawCheckOutInformationComponent implements OnInit {

  title:string;
  record: PartTracker;
  check : CheckOut;
  raw: RawCheckOut;

  constructor(
      private dialogRef: MatDialogRef<RawCheckOutInformationComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private spinner: NgxSpinnerService
    ) {
      this.title = data.PO_Part_Name;
      this.record = data;
      this.record.CheckOut_RawList.forEach(e=>{
        e.RawCheckOutList.forEach(async s=>{

          var snapshot = await this.db.database.ref('RawMaterial/'+s.Raw_ID).once('value');
          if (snapshot.exists()) {
            s.Raw_Name = snapshot.child('_Material_Name').val();
          }

        })

      })

    }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }
  view(check,box){
    this.check = check;
    this.raw = box;

  }
}
